import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createDocument, updateDocument, getDocument, snapshot, api } from "../config";
import _ from "lodash";
import moment from "moment";

export const refresh = createAsyncThunk(
    "dashboard/refresh",
    async (_,{getState,dispatch}) => {
        const {account} = getState();
        const {user} = account;
        if (user.dashboardDelay&&user.dashboardDelay>moment().unix()) return;
        dispatch(setDelaying(false));
        let aggDoc = await getDocument("user_aggregates", user.id);
        if(aggDoc) await updateDocument({entity:"user_aggregates",id:user.id},{refreshing:true});
        await api.aggregatesInitiate();
        let listener = snapshot("user_aggregates",user.id,async(doc)=>{
            if(doc.exists()&&!doc.data().refreshing){
                dispatch(setStats(doc.data()));
                dispatch(setRefreshing(false));
                listener();
            }
        },{includeMetadataChanges: true});
        return;
    }
);

export const loadData = createAsyncThunk("dashboard/loadData", async (data, { getState, dispatch }) => {
    let { account } = getState();
    let { user } = account;
    const delay = (user.dashboardDelay&&user.dashboardDelay>moment().unix());
    if (delay) {
        dispatch(setDelaying(true));
        setTimeout(() => {
            dispatch(refresh()); 
        }, ((parseInt(user.dashboardDelay)-moment().unix())*1000));
    }
    if (user?.brands?.length > 0) {
        let promises = []
        user.brands.forEach((b) => promises.push(getDocument("brands", b.id)))
        let response = await Promise.all(promises)
        let whiteLabel = response.filter((b) => b.whiteLabel)
        if (whiteLabel.length > 0) dispatch(setWhiteLabel(true))
    }
    let aggDoc = await getDocument("user_aggregates", user?.id)
    if (_.isEmpty(aggDoc)) {
        if(delay) await dispatch(refresh());
        return null
    } else {
        return aggDoc
    }

})

export const dashboardSlice = createSlice({
    name: "dashbard",
    initialState: {
        delaying: false,
        refreshing: false,
        loading: false,
        whiteLabel: true,
        sales: 0,
        completed: 0,
        donations: 0,
        avgSales: 0,
        avgDonation: 0,
        missingInfo: 0,
        pendingPayment: 0,
        pendingResults: 0,
        confirmResults: 0,
        pending: 0,
        salesByMonthDataset: [],
        salesByMonthLabels: [],
        salesByLocationDataset: [],
        salesByLocationLabels: [],
        topFundraisers: [],
        upcomingEvents: [],
        upcoming: 0,
    },
    reducers: {
        setStats: (state, action) => {
            if (action.payload) {
                for (let key in action.payload) {
                    if (key in state) state[key] = action.payload[key];
                }
            }
        },
        setRefreshing: (state, action) => {
            state.refreshing = action.payload;
        },
        setWhiteLabel: (state, action) => {
            state.whiteLabel = action.payload
        },
        setDelaying: (state, action) => {
            state.delaying = action.payload
        }
    },
    extraReducers: {
        [refresh.pending]: (state, action) => {
            state.refreshing = true;
            return;
        },
        [refresh.rejected]: (state, action) => {

            state.refreshing = false;
            return;
        },
        [loadData.pending]: (state, action) => {
            state.loading = true;
            return;
        },
        [loadData.fulfilled]: (state, action) => {
            if (action.payload) {
                for (let key in action.payload) {
                    if (key in state) state[key] = action.payload[key];
                }
            }
            state.loading = false;
            return;
        },
        [loadData.rejected]: (state, action) => {
            state.loading = false;
            return;
        },
    },
});

export const { setRefreshing, setWhiteLabel, setStats, setDelaying } = dashboardSlice.actions;
export default dashboardSlice.reducer;
