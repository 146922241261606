import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCollection, getDocument, createDocument, deleteDocument, snapshot, storageRef, fileURL, api } from "../config";
import moment from "moment";
import _ from "lodash";

const DEFAULT_FILTERS = {
  brands:[],
  external_ids:[],
  locations:[],
  statuses:[],
  types:[]
};

export const csvDownload = createAsyncThunk(
  "donations/csvDownload",
  async (d, { rejectWithValue }) => {
    try{
      if(d.exists&&d.data()&&d.data().destination) {        
        let sRef = await storageRef(d.data().destination);
        let url = await fileURL(sRef);
        let response = await fetch(url);
        let buffer = await response.arrayBuffer();
        url = window.URL.createObjectURL(new Blob([buffer],{type:'text/csv'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', sRef.name);
        document.body.appendChild(link);
        link.click();
        return true;
      }else if(d.exists&&d.data()&&d.data().errorMessage){
        return rejectWithValue(`ERROR: ${d.data().errorMessage}`);
      }
      return false;
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const csvExport = createAsyncThunk(
  "donations/csvExport",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try{
      const {algolia, account, donations} = getState();

      const rs = await api.exportDonations({
        columns:donations.columns,
        search:donations.searchTerm,
        filters:donations.algoliaFilters,
        userkey:algolia.key,
        indexName:donations.index.indexName,
        externalIDName:donations.externalIDName
      });

      const {data} = rs;

      return snapshot('user_exports',data.id,(d)=>dispatch(csvDownload(d)),{includeMetadataChanges:true});

    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const removeColumnset = createAsyncThunk(
  "donations/removeColumnset",
  async (_, { getState, rejectWithValue }) => {
    try{
      const {account,donations} = getState();
      await deleteDocument(`user_donation_columns/${account.user.uid}/sets`,donations.columnSet.id);
      return getCollection(`user_donation_columns/${account.user.uid}/sets`);
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const saveColumnset = createAsyncThunk(
  "donations/saveColumnset",
  async (payload, { getState, rejectWithValue }) => {
    try{
      const {account,donations} = getState();
      let param = {
          entity:`user_donation_columns/${account.user.uid}/sets`,
          merge:true
      };
      if (donations.columnSet) param.id = donations.columnSet.id;
      await createDocument(param,payload);
      return getCollection(`user_donation_columns/${account.user.uid}/sets`);
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const getUserPublic = createAsyncThunk(
  "donations/getUserPublic",
  async (_, { getState, rejectWithValue }) => {
    try{
      const {account} = getState();
      return getDocument(`users_public`, account.user.uid);
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const getUserColumnSets = createAsyncThunk(
  "donations/getUserColumnSets",
  async (_, { getState, rejectWithValue }) => {
    try{
      const {account} = getState();
      return getCollection(`user_donation_columns/${account.user.uid}/sets`);
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const getDonationsFromAlgolia = createAsyncThunk(
  "donations/getDonationsFromAlgolia",
  async (_, { getState, rejectWithValue }) => {
    const { donations, pager } = getState();
    try{
      const response = await donations.index.search(donations.searchTerm, {
        maxValuesPerFacet: 2000,
        filters: donations.algoliaFilters.join(' AND '),
        hitsPerPage: donations.limit,
        page: pager.page,
        facets: ['brand_name', 'external_id', 'location_name', 'status', 'donation_type'],
        sortFacetValuesBy: "alpha",
      });
      return response;
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const donationsSlice = createSlice({
  name: "donations",
  initialState: {
    loading:true,
    filtering:false,
    exporting:false,
    bulking:false,
    error:null,
    errorColumns:null,
    savingColumns:false,
    userPublic:{},
    activeModal:null,
    donations:[],
    order: 'created_unix',
    orderBy: 'asc',
    dateRange: {start:moment().startOf('year'),end:moment().endOf('year')},
    selectedTab:'upcoming',
    lastTab:'upcoming',
    activeModal:null,
    showFilters:false,
    index:null,
    filterBrands:[],
    selectedBrands:[],
    selectedExternalIDs:[],
    selectedLocations:[],
    selectedStatuses:[],
    selectedTypes:[],
    filterExternalIDs:[],
    filterLocations:[],
    filterStatuses:[],
    filterTypes:[],
    externalIDName:'External ID',
    includeExternal:false,
    searchTerm:'',
    algoliaFilters:[],
    filters:DEFAULT_FILTERS,
    limit:20,
    pages:0,
    userColumnSets:[],
    columnSet:null,
    columns:['created','due_date','fulfill_date','donation_type','brand','location_name','status','donation_value','fundraiser_org_name','fundraiser_name'],
    tempColumns:[],
    fields: [
      {
          key: 'id',
          label: 'ID',
          sortable: false
      },
      {
          key: 'created',
          label: 'Created',
          sortable: true
      },
      {
          key: 'due_date',
          label: 'Due Date',
          sortable: true
      },
      {
          key: 'fulfill_date',
          label: 'Fulfill Date',
          sortable: true
      },
      {
         key: 'donation_type',
         label: 'Type',
         sortable: false
      },
     {
        key: 'brand',
        label: 'Brand',
        sortable: false
     },
     {
         key: 'external_id',
         label: 'External ID',
         sortable: false
     },
     {
         key: 'location_name',
         label: 'Location Name',
         sortable: true
     },
     {
         key: 'location_address',
         label: 'Location Address',
         sortable: false
     },
     {
         key: 'location_city',
         label: 'Location City',
         sortable: false
     },
     {
         key: 'location_state',
         label: 'Location State',
         sortable: false
     },
     {
         key: 'location_zip',
         label: 'Location Zip',
         sortable: false
     },
     {
         key: 'status',
         label: 'Status',
         sortable: false,
         class: 'text-center'
     },
     {
         key: 'donation_value',
         label: 'Donation Value',
         sortable: true,
         sortDirection:'desc',
         class:'text-end'
     },
     {
         key: 'approved_date',
         label: 'Approved Date',
         sortable: false,
     },
     {
         key: 'fundraiser_org_name',
         label: 'Fundraiser',
         sortable: true
     },
     {
         key: 'fundraiser_name',
         label: 'Fundraiser Contact',
         sortable: false
     },
     {
         key: 'fundraiser_org_address',
         label: 'Fundraiser Address',
         sortable: false
     },
     {
         key: 'fundraiser_org_city',
         label: 'Fundraiser City',
         sortable: false
     },
     {
         key: 'fundraiser_org_state',
         label: 'Fundraiser State',
         sortable: false
     },
     {
         key: 'fundraiser_org_zip',
         label: 'Fundraiser Zip',
         sortable: false
     },
     {
         key: 'fundraiser_org_taxid',
         label: 'Fundraiser TaxID',
         sortable: false
     },
     {
         key: 'fundraiser_taxid_verified',
         label: 'TaxID Verified',
         sortable: false,
         class: 'text-center'
     },
     {
         key: 'fundraiser_org_taxdoc',
         label: 'Tax Document',
         sortable: false,
         class: 'text-center'
     },
     {
         key: 'fundraiser_optin_marketing',
         label: 'Opt in Marketing',
         sortable: false,
         class: 'text-center'
     },
     {
         key: 'fundraiser_email',
         label: 'Fundraiser Email',
         sortable: false
     },
     {
         key: 'fundraiser_phone',
         label: 'Fundraiser Phone',
         sortable: false
     }
   ],
   listener:null
  },
  reducers: {
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setColumns: (state, action) => {
      state.columns = action.payload;
    },
    setColumnSet: (state, action) => {
      state.columnSet = action.payload;
    },
    setActiveModal: (state,action) => {
      if(action.payload==='columns') state.errorColumns = null;
      state.activeModal = action.payload;
    },
    setSelectedTab: (state,action) => {
      state.selectedTab = action.payload;
    },
    setLastTab: (state,action) => {
      state.lastTab = action.payload;
    },
    setSearchTerm: (state,action) => {
      state.searchTerm = action.payload;
    },
    removeFilterItem: (state,action) => {
      state.filters[action.payload.type].splice(action.payload.index,1);
    },
    clearAllFilters: (state) => {
      state.filters = DEFAULT_FILTERS;
    },
    applyFilters: (state, action) => {
      
      let algoliaFilters = [];
      let filters = state.filters;

      state.filtering = true;
      
      let dateRangeStart = null;
      let dateRangeEnd = null;
      
      switch(state.selectedTab){
        case 'date-range':
          dateRangeStart = state.dateRange.start;
          dateRangeEnd = state.dateRange.end;
          algoliaFilters.push('fulfill_unix > ' + moment(dateRangeStart).tz(moment.tz.guess()).startOf('day').unix().toString());
          algoliaFilters.push('fulfill_unix < ' + moment(dateRangeEnd).tz(moment.tz.guess()).endOf('day').unix().toString());
        break;
        case 'upcoming':
        case 'past':
          algoliaFilters.push(`tab:"${_.upperCase(state.selectedTab)}"`);
        break;
      }

      if(state.showFilters){
        filters.brands = filters.brands.concat(state.selectedBrands.map(b=>b));
        filters.brands = filters.brands.filter((a,b)=>filters.brands.indexOf(a) == b);
        filters.external_ids = filters.external_ids.concat(state.selectedExternalIDs.map(l=>l));
        filters.external_ids = filters.external_ids.filter((a,b)=>filters.external_ids.indexOf(a) == b);
        filters.locations = filters.locations.concat(state.selectedLocations.map(l=>l));
        filters.locations = filters.locations.filter((a,b)=>filters.locations.indexOf(a) == b);
        filters.statuses = filters.statuses.concat(state.selectedStatuses.map(s=>s));
        filters.statuses = filters.statuses.filter((a,b)=>filters.statuses.indexOf(a) == b);
        filters.types = filters.types.concat(state.selectedTypes.map(s=>s));
        filters.types = filters.types.filter((a,b)=>filters.types.indexOf(a) == b);
      }

      if(filters.brands.length>0){
        algoliaFilters.push('('+filters.brands.map(b=>'brand_name:"'+b+'"').join(' OR ')+')');
      }

      if(filters.locations.length>0){
        algoliaFilters.push('('+filters.locations.map(l=>'location_name:"'+l+'"').join(' OR ')+')');
      }

      if(filters.external_ids.length>0){
        algoliaFilters.push('('+filters.external_ids.map(l=>'external_id:"'+l+'"').join(' OR ')+')');
      }

      if(filters.statuses.length>0){
        algoliaFilters.push('('+filters.statuses.map(l=>'status:"'+l+'"').join(' OR ')+')');
      }

      if(filters.types.length>0){
        algoliaFilters.push('('+filters.types.map(l=>'donation_type:"'+l+'"').join(' OR ')+')');
      }

      state.selectedBrands = [];
      state.selectedExternalIDs = [];
      state.selectedLocations = [];
      state.selectedStatuses = [];
      state.selectedTypes = [];

      state.filters = filters;
      state.algoliaFilters = algoliaFilters;

    },
    onFilterChange: (state,action) => {
      state[action.payload.field]=action.payload.value.map(v=>v.value);
    },
    clearSearch: (state) => {

    },
    setDonationsIndex: (state, action) => {
      state.index = action.payload;
    },
    setShowFilters: (state, action) => {
      state.showFilters = action.payload;
    },
    setTempColumns: (state, action) => {
      state.tempColumns = action.payload;
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload;
    }
  },
  extraReducers: {
    [csvDownload.fulfilled]: (state, action) => {
      if(action.payload) {
        state.exporting = false;
      }
      return;
    },
    [csvDownload.rejected]: (state, action) => {
      state.listener();
      state.error = action.payload;
      state.exporting = false;
      return;
    },
    [csvExport.fulfilled]: (state, action) => {
      state.listener = action.payload;
      return;
    },
    [csvExport.pending]: (state, action) => {
      state.listener = null;
      state.error = null;
      state.exporting = true;
      return;
    },
    [csvExport.rejected]: (state, action) => {
      state.listener = null;
      state.error = action.payload;
      state.exporting = false;
      return;
    },
    [removeColumnset.fulfilled]: (state, action) => {
      state.userColumnSets = action.payload;
      state.savingColumns = false;
      state.activeModal =  null;
      return;
    },
    [removeColumnset.pending]: (state, action) => {
      state.errorColumns = null;
      state.savingColumns = true;
      return;
    },
    [removeColumnset.rejected]: (state, action) => {
      state.errorColumns = action.payload;
      state.savingColumns = false;
      return;
    },
    [saveColumnset.fulfilled]: (state, action) => {
      state.userColumnSets = action.payload;
      state.savingColumns = false;
      state.activeModal =  null;
      return;
    },
    [saveColumnset.pending]: (state, action) => {
      state.errorColumns = null;
      state.savingColumns = true;
      return;
    },
    [saveColumnset.rejected]: (state, action) => {
      state.errorColumns = action.payload;
      state.savingColumns = false;
      return;
    },
    [getUserPublic.fulfilled]: (state, action) => {
      state.userPublic = action.payload?action.payload:{};
      return;
    },
    [getUserPublic.pending]: (state, action) => {
      state.userPublic = {};
      return;
    },
    [getUserPublic.rejected]: (state, action) => {
      state.error = action.payload;
      state.userPublic = {};
      return;
    },
    [getUserColumnSets.fulfilled]: (state, action) => {
      state.userColumnSets = action.payload;
      return;
    },
    [getUserColumnSets.pending]: (state, action) => {
      state.userColumnSets = [];
      return;
    },
    [getUserColumnSets.rejected]: (state, action) => {
      state.error = action.payload;
      state.userColumnSets = [];
      return;
    },
    [getDonationsFromAlgolia.fulfilled]: (state, action) => {
      state.pages=action.payload.nbPages;
      if(action.payload.facets.brand_name) state.filterBrands = Object.keys(action.payload.facets.brand_name).map(b=>Object.assign({},{value:b,label:b}));
      if(action.payload.facets.location_name) state.filterLocations = Object.keys(action.payload.facets.location_name).map(l=>Object.assign({},{value:l,label:l}));
      if(action.payload.facets.external_id) state.filterExternalIDs = Object.keys(action.payload.facets.external_id).map(nbr=>Object.assign({},{value:nbr,label:nbr}));
      if(action.payload.facets.status) state.filterStatuses = Object.keys(action.payload.facets.status).map(s=>Object.assign({},{value:s,label:s}));
      if(action.payload.facets.donation_type) state.filterTypes = Object.keys(action.payload.facets.donation_type).map(s=>Object.assign({},{value:s,label:s}));
      state.donations = action.payload.hits.map(e=>{return {id:e.objectID,...e};});
      state.loading = false;
      state.filtering = false;
      state.showFilters = false;
      return;
    },
    [getDonationsFromAlgolia.pending]: (state, action) => {
      state.loading = true;
      return;
    },
    [getDonationsFromAlgolia.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      return;
    }
  }
});

export default donationsSlice.reducer;
export const {
  setLimit,
  setColumnSet,
  applyFilters,
  setSearchTerm,
  setActiveModal,
  setShowFilters,
  clearSearch,
  setDonationsIndex,
  onFilterChange,
  removeFilterItem,
  clearAllFilters,
  setSelectedTab,
  setLastTab,
  setTempColumns,
  setColumns,
  setDateRange,
  setOrder,
  setOrderBy
} = donationsSlice.actions;
