import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  api,
  deleteDocument,
  getCollection,
  getDocument,
  snapshot,
} from "../config";
import moment from "moment";

const applyFilter = (filter, arr) => {
  return arr.filter((l) => {
    let s = filter.toLowerCase();
    if (s.length === 0) return true;
    if (
      l.address?.toLowerCase().indexOf(s) > -1 ||
      l.brand?.toLowerCase().indexOf(s) > -1 ||
      l.city?.toLowerCase().indexOf(s) > -1 ||
      l.externalID?.toLowerCase().indexOf(s) > -1 ||
      l.location?.toLowerCase().indexOf(s) > -1 ||
      l.state?.toLowerCase().indexOf(s) > -1 ||
      l.zip?.toLowerCase().indexOf(s) > -1
    ) {
      return true;
    }
    return false;
  });
};
const applySorting = (order, orderBy, arr) => {
  return arr.sort((a, b) => {
    if (orderBy === "ascending") {
      if (a[order] < b[order]) {
        return -1;
      }
      if (a[order] > b[order]) {
        return 1;
      }
    } else {
      if (a[order] > b[order]) {
        return -1;
      }
      if (a[order] < b[order]) {
        return 1;
      }
    }

    return 0;
  });
};
const applyPaging = (limit, page, arr) => {
  return arr.filter((_, i) => {
    let offset = limit * (page + 1);
    return i >= offset - limit && i < offset;
  });
};

export const load = createAsyncThunk(
  "stats/load",
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { account } = getState();
    let ref = null;
    var br = null;
    try {
      if (account.user.permissions.admin) {
        dispatch(setExtra(true));
        dispatch(setStatsType(null));
        dispatch(setFilterTypes([]));
        ref = await getCollection("brands", { order: ["brand", "asc"] });
        br = ref;
      } else if (account.user.parentUserID) {
        ref = await getDocument("users", account.user.parentUserID);
        if (ref && ref.brands) br = ref.brands;
        ref = await getDocument("users_public", account.user.parentUserID);
        if (ref && ref.extraFields && ref.extraFields.payment)
          dispatch(setExtra(true));
      } else if (account.user.brands) {
        ref = await getDocument("users_public", account.user.id);
        if (ref && ref.extraFields && ref.extraFields.payment)
          dispatch(setExtra(true));
        br = account.user.brands;
        if (
          account.user.corporate &&
          account.user.brands &&
          account.user.brands.length == 1
        ) {
          dispatch(loadAccounts(account.user.brands[0].id));
        }
      }
      dispatch(loadStats());
      return br;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const loadAccounts = createAsyncThunk(
  "stats/loadAccounts",
  async (brandID, { getState, dispatch, rejectWithValue }) => {
    const { stats } = getState();
    const { filterTypes } = stats;
    try {
      let ref = await getDocument("brands", brandID);
      let promises = [];
      if (ref) {
        Object.keys(ref.users).forEach((id) =>
          promises.push(getDocument("users", id))
        );
        let accounts = await Promise.all(promises);
        dispatch(
          setFilterTypes([
            ...filterTypes,
            {
              label: "Account Holder",
              options: accounts
                .map((a) => {
                  if (a)
                    return { value: a.id, label: a.name, group: "account" };
                  else return null;
                })
                .filter((a) => a)
                .sort((a, b) =>
                  a.label > b.label ? 1 : b.label > a.label ? -1 : 0
                ),
            },
          ])
        );
      } else {
        dispatch(
          setFilterTypes(
            filterTypes.filter((t) => t.label !== "Account Holder")
          )
        );
      }
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const setSelectedBrand = createAsyncThunk(
  "stats/setSelectedBrand",
  async (brand, { dispatch }) => {
    let rs = await getDocument("brands", brand.value);
    dispatch(setIncludeExternal(rs?.includeExternal));
    if (rs?.externalIDName) dispatch(setExternalIDName(rs?.externalIDName));
    else dispatch(setExternalIDName("Ext. ID"));
    return brand;
  }
);

export const loadStats = createAsyncThunk(
  "stats/loadStats",
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { stats, account } = getState();

    const { selectedBrand, dateRange, statsType, brands } = stats;

    try {
      let ref = await getDocument("user_stats", account.user.id);
      if (ref) await deleteDocument("user_stats", account.user.id);
      let dispatches = {
        dateRange: {
          start: dateRange.start.unix(),
          end: dateRange.end.unix(),
        },
        statsType:
          statsType && statsType.group === "location"
            ? statsType.value
            : "mine"
      };
      if(statsType && statsType.group === "account") dispatches.eventUserID = statsType.value;
      if (selectedBrand) {
        if (account.user.permissions.admin && !statsType)
          dispatches.statsType = "brand";
        dispatches.brandID = selectedBrand.value;
      } else {
        if (brands && brands.length == 1) {
          let brandRef = await getDocument("brands", brands[0].value);
          dispatch(setIncludeExternal(brandRef?.includeExternal));
          if (brandRef?.externalIDName)
            dispatch(setExternalIDName(brandRef.externalIDName));
          else dispatch(setExternalIDName("Ext. ID"));
        }
      }

      await api.statsAll(dispatches);

      let listener = snapshot(
        "user_stats",
        account.user.id,
        async (doc) => {
          if (doc.exists()) {
            dispatch(storeStats({ ...doc.data() }));
            let rs = await getCollection(
              `user_stats/${account.user.id}/locations`,
              {
                order: ["location", "asc"],
              }
            );
            dispatch(setLocations(rs));
            dispatch(setPages(Math.ceil(rs.length / stats.limit)));
            let l = [...rs];
            l = applySorting("location", "ascending", l);
            l = applyPaging(stats.limit, 0, l);
            dispatch(setFilteredLocations([...l]));
            listener();
          }
        },
        { includeMetadataChanges: true }
      );

      return;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.message);
    }
  }
);

export const filterLocations = createAsyncThunk(
  "stats/filterLocations",
  async (filter, { getState, rejectWithValue }) => {
    try {
      const { stats, pager } = getState();
      let locations = [...stats.locations];
      locations = applyFilter(filter, locations);
      let pages = Math.ceil(locations.length / stats.limit);
      locations = applySorting(stats.order, stats.orderBy, locations);
      locations = applyPaging(stats.limit, pager.page, locations);
      return {
        pages: pages,
        filter: filter,
        locations: locations,
      };
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const sortLocations = createAsyncThunk(
  "stats/sortLocations",
  async (sort, { getState, rejectWithValue }) => {
    try {
      const { stats, pager } = getState();
      let orderBy =
        stats.order === sort
          ? stats.orderBy === "descending"
            ? "ascending"
            : "descending"
          : "ascending";
      let locations = [...stats.locations];
      locations = applyFilter(stats.locationFilter, locations);
      locations = applySorting(sort, orderBy, locations);
      locations = applyPaging(stats.limit, pager.page, locations);
      return {
        orderBy: orderBy,
        order: sort,
        locations: locations,
      };
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const statsSlice = createSlice({
  name: "stats",
  initialState: {
    error: null,
    loading: false,
    extra: false,
    showOptions: false,
    brands: null,
    accounts: null,
    selectedBrand: null,
    selectedAccount: null,
    includeExternal: false,
    exporting: false,
    externalIDName: "Ext. ID",
    chartType: "month",
    dateType: "This Year",
    statsType: {
      value: "mine",
      label: "Locations",
      group: "location",
    },
    dateRange: {
      start: moment().startOf("year"),
      end: moment().endOf("year"),
    },
    customDateRange: {
      start: moment().startOf("year").toDate(),
      end: moment().endOf("year").toDate(),
    },
    filterTypes: [
      {
        label: "Location Type",
        options: [
          { value: "mine", label: "My Locations", group: "location" },
          { value: "brand", label: "Brand-wide", group: "location" },
        ],
      },
    ],
    chartData: null,
    sales: 0,
    instore_sales: 0,
    online_sales: 0,
    instore_transactions: 0,
    online_transactions: 0,
    completed: 0,
    hasSales: 0,
    noSales: 0,
    zeroSales: 0,
    successful: 0,
    thresholdNotMet: 0,
    donations: 0,
    avgSales: 0,
    avgDonation: 0,
    locations: [],
    filteredLocations: [],
    locationSize: 0,
    currentPage: 1,
    totalRows: 0,
    locationFilter: "",
    dataSalesByDate: {
      title: "Total Sales",
      datasets: [
        {
          label: "Total Sales",
          borderColor: ["#00d78b"],
          backgroundColor: ["#00d78b"],
          data: [],
        },
      ],
      labels: [],
    },
    datasetOptions: {
      label: "Total Sales",
      borderColor: "#00d78b",
      backgroundColor: "#00d78b",
    },
    exporting: false,
    locationFields: [
      {
        key: "brand",
        label: "Brand",
        sortable: true,
      },
      {
        key: "location",
        label: "Location",
        sortable: true,
      },
      {
        key: "address",
        label: "Address",
        sortable: true,
      },
      {
        key: "city",
        label: "City",
        sortable: true,
      },
      {
        key: "state",
        label: "State",
        sortable: true,
      },
      {
        key: "zip",
        label: "Zip",
        sortable: true,
      },
      {
        key: "sales",
        label: "Total Sales",
        sortable: true,
        class: "text-end",
      },
      {
        key: "donations",
        label: "Total Donations",
        sortable: true,
        class: "text-end",
      },
      {
        key: "avgSales",
        label: "Avg Sales",
        sortable: true,
        class: "text-end",
      },
      {
        key: "avgDonation",
        label: "Avg Donation",
        sortable: true,
        class: "text-end",
      },
      {
        key: "completed",
        label: "Approved",
        sortable: true,
        class: "text-end",
      },
      {
        key: "successful",
        label: "Successful",
        sortable: true,
        class: "text-end",
      },
      {
        key: "nosales",
        label: "Sales Not Entered",
        sortable: true,
        class: "text-end",
      },
      {
        key: "hassales",
        label: "Sales > Zero",
        sortable: true,
        class: "text-end",
      },
      {
        key: "zerosales",
        label: "Sales = Zero",
        sortable: true,
        class: "text-end",
      },
      {
        key: "thresholdnotmet",
        label: "Threshold Not Met",
        sortable: true,
        class: "text-end",
      },
    ],
    order: "location",
    orderBy: "asc",
    pages: 0,
    limit: 20,
  },
  reducers: {
    setPages: (state, action) => {
      state.pages = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setExtra: (state, action) => {
      state.extra = action.payload;
    },
    setStatsType: (state, action) => {
      state.statsType = action.payload;
    },
    setFilterTypes: (state, action) => {
      state.filterTypes = action.payload;
    },
    setLocationSize: (state, action) => {
      state.locationSize = action.payload;
    },
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
    setFilteredLocations: (state, action) => {
      state.filteredLocations = action.payload;
    },
    setIncludeExternal: (state, action) => {
      state.includeExternal = action.payload;
    },
    setExternalIDName: (state, action) => {
      state.externalIDName = action.payload;
    },
    setTotalRows: (state, action) => {
      state.totalRows = action.payload;
    },
    storeStats: (state, action) => {
      if (action.payload) {
        let data = action.payload;
        if (Object.keys(data).length > 0) {
          Object.keys(data).forEach((k) => {
            if (k == "locations") return;
            if (k in state) state[k] = data[k];
          });
          if (data.chartData) {
            state.chartData = data.chartData;
            state.dataSalesByDate = {
              datasets: [
                {
                  data: data.chartData[state.chartType].salesByDateDataset,
                  ...state.datasetOptions,
                },
              ],
              labels: data.chartData[state.chartType].salesByDateLabels,
            };
          }
        }
      }
      state.loading = false;
    },
    setCustomDateRange: (state, action) => {
      state.customDateRange = action.payload;
    },
    setDateType: (state, action) => {
      state.dateType = action.payload;
    },
    setChartType: (state, action) => {
      state.chartType = action.payload;
    },
    setDataSalesByDate: (state, action) => {
      state.dataSalesByDate = action.payload;
    },
    setExporting: (state, action) => {
      state.exporting = action.payload;
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload;
    },
  },
  extraReducers: {
    [load.pending]: (state, action) => {
      state.error = null;
      state.loading = true;
      return;
    },
    [load.fulfilled]: (state, action) => {
      if (action.payload)
        state.brands = action.payload
          .map((b) => Object.assign({ value: b.id, label: b.brand }))
          .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
      else state.brands = null;
      return;
    },
    [load.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      return;
    },
    [loadStats.pending]: (state, action) => {
      state.error = null;
      state.locations = [];
      state.filteredLocations = [];
      state.locationFilter = "";
      state.includeExternal = false;
      state.externalIDName = "Ext. ID";
      state.loading = true;
      return;
    },
    [loadStats.fulfilled]: (state, action) => {
      return;
    },
    [loadStats.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      return;
    },
    [setSelectedBrand.pending]: (state, action) => {
      state.selectedBrand = action.payload;
      return;
    },
    [setSelectedBrand.fulfilled]: (state, action) => {
      state.selectedBrand = action.payload;
      return;
    },
    [setSelectedBrand.rejected]: (state, action) => {
      state.selectedBrand = action.payload;
      return;
    },
    [filterLocations.fulfilled]: (state, action) => {
      state.locationFilter = action.payload.filter;
      state.filteredLocations = action.payload.locations;
      state.pages = action.payload.pages;
      return;
    },
    [sortLocations.fulfilled]: (state, action) => {
      state.order = action.payload.order;
      state.orderBy = action.payload.orderBy;
      state.filteredLocations = action.payload.locations;
      state.sorting = false;
      return;
    },
  },
});

export const {
  setLoading,
  setExtra,
  setStatsType,
  setFilterTypes,
  setLocationSize,
  setLocations,
  setIncludeExternal,
  setExternalIDName,
  setTotalRows,
  storeStats,
  setCustomDateRange,
  setDateType,
  setChartType,
  setDataSalesByDate,
  setExporting,
  setDateRange,
  setPages,
  setFilteredLocations,
} = statsSlice.actions;
export default statsSlice.reducer;
