import { createSlice } from "@reduxjs/toolkit";

const DEFAULT_FIELD_VALUES = {
    index:-1,
    question:'',
    helpText:'',
    required:false,
    type:'text'

}

export const formBuilderSlice = createSlice({
  name: "formBuilder",
  initialState: {
    collapse:false,
    fields:[],
    activeField:DEFAULT_FIELD_VALUES,
    mode:'edit'
  },
  reducers: {
    setMode: (state,action) => {
        state.mode = action.payload;
    },
    setCollapse: (state,action) => {
        state.collapse = action.payload;
    },
    editField: (state,action) => {
        state.activeField = {...action.payload};
        state.collapse = true;
    },
    addField: (state) => {
        state.activeField = DEFAULT_FIELD_VALUES;
    },
    removeField: (state, action) => {
        state.fields.splice(action.payload,1);
        state.fields = state.fields.map((f,i)=>Object.assign({...f},{index:i}));
    },
    upsertField: (state, action) => {
        if(state.activeField.index>-1){
            console.log({...state.activeField});
            state.fields[state.activeField.index] = {...state.activeField};
        }else{
            state.fields.push(Object.assign({...state.activeField,index:state.fields.length}));
        }
        
    },
    updateActiveField: (state, action) => {
        state.activeField = Object.assign({...state.activeField},{...action.payload});
    },
    sortUp: (state, action) => {
        if(action.payload>0){
            state.fields[action.payload].index = action.payload-1;
            state.fields[action.payload-1].index = action.payload;
            var ele = state.fields.splice(action.payload, 1).pop();
            state.fields.splice(action.payload-1, 0, ele);
        }
    },
    sortDown: (state, action) => {
        if(action.payload<state.fields.length-1){
            state.fields[action.payload].index = action.payload+1;
            state.fields[action.payload+1].index = action.payload;
            var ele = state.fields.splice(action.payload, 1).pop();
            state.fields.splice(action.payload+1, 0, ele);
        }
    },
    setFields: (state, action) => {
        state.fields = action.payload;
    }
  },
  extraReducers: {},
});

export const {
    setCollapse,
    addField,
    editField,
    removeField,
    upsertField,
    updateActiveField,
    sortUp,
    sortDown,
    setMode,
    setFields
} = formBuilderSlice.actions;
export default formBuilderSlice.reducer;
