import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  updateDocument,
  getCollection,
  getDocument,
  createDocument
} from "../config";
import _ from "lodash";
import moment from "moment";

export const loadDonationTypes = createAsyncThunk(
  "donationTypes/loadDonationTypes",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { account } = getState();
      
      let user = account.user;

      let rs = await getCollection("donation_types", {
        where: [
          ["userID", "==", user.parentUserID ? user.parentUserID : user.id],
        ],
        joins: [
          {
            key: "brandID",
            collection: "brands",
            field: "brand",
          },
        ],
      });
      return rs;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const deleteDonationType = createAsyncThunk(
  "donationTypes/deleteDonationType",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { donationTypes } = getState();
      let rs = await updateDocument(
        {
          entity: "donation_types",
          id: donationTypes.event.id,
        },
        {
          deleted: true,
        }
      );
      return;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const loadBrands = createAsyncThunk(
  "donationTypes/loadBrands",
  async (_, { getState, rejectWithValue }) => {
    const { account } = getState();
    const { user } = account;
    return getCollection("brands", {
      where: [
        [
          "users." + (user.parentUserID ? user.parentUserID : user.id),
          "==",
          true,
        ],
      ],
    });
  }
);

export const loadLocations = createAsyncThunk(
  "donationTypes/loadLocations",
  async (data, { getState, rejectWithValue }) => {
    
    const { account } = getState();
    const { user } = account;

    try {
      if (!data) return false;
      console.log(data);
      let brand = await getDocument("brands", data);
      if (_.isEmpty(brand)) return false;
      let locations = await getCollection("locations", {
        where: [
          ["brandID", "==", data],
          [
            "users." + (user.parentUserID ? user.parentUserID : user.id),
            "==",
            true,
          ],
        ],
      });
      locations = locations.filter(l=>!l.deleted&&!["Non Participating","Temporarily Closed","Scheduled","Referral"].includes(l.status));
      return { brand, locations };
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const loadRules = createAsyncThunk(
  "donationTypes/loadRules",
  async (brandID, { getState }) => {
    const { account } = getState();
    const { user } = account;
    if (!brandID) return false;
    let brand = await getDocument("brands", brandID);
    return { user, brand };
  }
);
export const save = createAsyncThunk(
  "donationTypes/save",
  async (_, { getState, rejectWithValue }) => {
    try {
      const {} = getState();
      return;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const donationTypesSlice = createSlice({
  name: "donationTypes",
  initialState: {
    processing: false,
    loading: true,
    loadingError: null,
    loadingLocations: false,
    donations: [],
    optionsBrands: [],
    optionsLocations: [],
    locationsData: [],
    loadLocationsError: null,
    activeModal: null,
    shareLink: null,
    donation: null,
    showOnboarding: false,
    onboardTitle: "What do you want to call this donation type?",
    step: 0
  },
  reducers: {
    setDonation: (state, action) => {
      state.donation = action.payload;
    },
    setActiveModal: (state, action) => {
      state.activeModal = action.payload;
    },
    setShareLink: (state, action) => {
      state.shareLink = action.payload;
    },
    setShowOnboarding: (state, action) => {
      state.showOnboarding = action.payload;
    },
    resetState: (state, action) => {
      state.onboardTitle = "What do you want to call this donation type?";
      state.step = 0;
    },
  },
  extraReducers: {
    [loadDonationTypes.fulfilled]: (state, action) => {
      state.donations = action.payload;
      state.loading = false;
      return;
    },
    [loadDonationTypes.pending]: (state, action) => {
      state.loadingError = null;
      state.loading = true;
      return;
    },
    [loadDonationTypes.rejected]: (state, action) => {
      state.loadingError = action.payload;
      state.loading = false;
      return;
    },
    [deleteDonationType.fulfilled]: (state, action) => {
      state.donations = state.donations.filter((e) => e.id !== state.event.id);
      state.donation = null;
      state.activeModal = null;
      state.deleting = false;
      return;
    },
    [deleteDonationType.pending]: (state, action) => {
      state.deleteError = null;
      state.deleting = true;
      return;
    },
    [deleteDonationType.rejected]: (state, action) => {
      state.deleteError = action.payload;
      state.deleting = false;
      return;
    },
    [loadBrands.fulfilled]: (state, action) => {
      if (action.payload?.length) {
        var brandData = {};
        var optBrands = [];
        var br = [];
        let brands = action.payload;
        brands.forEach((brand) => {
          optBrands.push({ value: brand.id, label: brand.brand });
          brandData = {
            [".key"]: brand.id,
            brand: brand.brand,
            logo: brand.logo ? brand.logo : false,
            menu: brand.menu ? brand.menu : "",
            rules: brand.rules ? brand.rules : [],
            website: brand.website ? brand.website : "",
          };
          br.push(brandData);
        });
        state.optionsBrands = optBrands;
        state.brands = br;
        if (optBrands.length == 1) {
          state.brand = brandData;
        }
      }
      return;
    },
    [loadBrands.pending]: (state, action) => {
      return;
    },
    [loadBrands.rejected]: (state, action) => {
      return;
    },
    [loadLocations.fulfilled]: (state, action) => {
      if (action.payload.locations?.length) {
        let locations = action.payload.locations;
        var optLocations = [];
        var locat = [];
        locations.forEach((location) => {
          if (!location.deleted) {
            locat[location.id] = location;
            optLocations.push({
              value: location.id,
              label:
                (action.payload.brand.includeExternal && location.externalID
                  ? location.externalID + " - "
                  : "") + location.location,
            });
          }
        });
        state.optionsLocations = optLocations.sort((a, b) =>
          a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        );
        state.locationsData = locat;
      }else{
        state.optionsLocations = [];
        state.locationsData = [];
      }
      state.loadingLocations = false;
      return;
    },
    [loadLocations.pending]: (state, action) => {
      state.loadLocationsError = false;
      state.loadingLocations = true;
      return;
    },
    [loadLocations.rejected]: (state, action) => {
      state.loadLocationsError = action.payload;
      state.loadingLocations = false;
      return;
    },

    [loadRules.fulfilled]: (state, action) => {
      if (!_.isEmpty(action.payload.brand)) {
        let brand = action.payload.brand;
        let br = {};
        br = {
          [".key"]: brand.id,
          brand: brand.brand,
          logo: brand.logo ? brand.logo : false,
          menu: brand.menu ? brand.menu : "",
          rules: brand.rules ? brand.rules : [],
          website: brand.website ? brand.website : "",
        };
        state.brand = br;
        if (brand.rules) {
          state.rulesOptions = brand.rules;
        } else {
          state.rulesOptions = [];
        }
        if (action.payload.user.rules && action.payload.user.rules[brand.id])
          state.rulesOptions = state.rulesOptions.concat(
            action.payload.user.rules[brand.id]
          );
      }
      state.processing = false;
      return;
    },
    [loadRules.pending]: (state, action) => {
      state.processing = true;
      return;
    },
    [loadRules.rejected]: (state, action) => {
      state.processing = false;
      return;
    },
    [save.fulfilled]: (state, action) => {
      state.donations.unshift({...action.payload});
      state.showOnboarding = false;
      state.step += 1;
      state.saving = false;
    },
    [save.pending]: (state, action) => {
      state.savingError = null;
      state.saving = true;
    },
    [save.rejected]: (state, action) => {
      state.savingError = action.payload;
      state.saving = false;
    },
  },
});

export const {
  setActiveModal,
  setShareLink,
  setDonation,
  setShowOnboarding,
  resetState
} = donationTypesSlice.actions;
export default donationTypesSlice.reducer;
