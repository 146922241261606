import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  updateDocument,
  getDocument,
  createDocument,
  authStateChanged,
  auth,
  signIn,
  signInMethods,
  getCollection,
} from "../config";
import { navigate } from "gatsby";
import moment from "moment";
import _ from "lodash";

export const accountSlice = createSlice({
  name: "account",
  initialState: {
    authorized: false,
    authenticated: false,
    processing: false,
    processingContinue: false,
    processingLogin: false,
    processingOrg: false,
    processingPerInfo: false,
    fulfilledPerInfo: false,
    errorPerInfo: null,
    accountEmailError: "",
    emailVerified: false,
    currentUser: null,
    user: null,
    authing: true,
    permissions: null,
    orgs: [],
    orgsError: null,
    reservations: [],
    reservationsError: null,
    fetching: true,
    fetchingOrgsFulfilled: false,
    checkingOrgsFulfilled: false,
    subscription: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAuthing: (state, action) => {
      state.authing = action.payload;
    },
    setAuthorized: (state, action) => {
      state.authorized = action.payload;
    },
    setAuthenticated: (state, action) => {
      state.authenticated = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAccountEmailError: (state, action) => {
      state.accountEmailError = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    resetPerInfo: (state, action) => {
      state.fulfilledPerInfo = false;
      state.errorPerInfo = null;
    },
    setSubscription: (state, action) => {
      state.subscription = action.payload;
    },
  },
  extraReducers: {},
});

export default accountSlice.reducer;
export const {
  setLoading,
  setUser,
  setAccountEmailError,
  setAuthorized,
  setCurrentUser,
  setAuthing,
  setPermissions,
  setAuthenticated,
  resetPerInfo,
  setSubscription,
} = accountSlice.actions;
