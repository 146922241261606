import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCollection, getDocument, updateDocument } from "../config";
import _ from "lodash";

export const load = createAsyncThunk(
  "profile/laod",
  async (user, { getState }) => {
    if ("permissions" in user) {
      let promises = [];
      if (user.brands) {
        user.brands.forEach((b) => promises.push(getDocument("brands", b.id)));
      }
      return Promise.all(promises);
    }
  }
);

export const loadInvitedUsers = createAsyncThunk(
  "profile/loadInvitedUsers",
  async (user) => {
    return getCollection("users", { where: [["parentUserID", "==", user.id]] });
  }
);

export const loadPendingInvitedUsers = createAsyncThunk(
  "profile/loadPendingInvitedUsers",
  async (user) => {
    return getCollection("user_invites", {
      where: [["parentUserID", "==", user.id]],
    });
  }
);

export const updateUser = createAsyncThunk(
  "profile/updateUser",
  async (data, { getState }) => {
    const { account } = getState();
    const { user } = account;
    await updateDocument({ entity: "users", id: user.id }, data);
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    loading: false,
    loadingInvitedUsers: false,
    loadingPendingInvitedUsers: false,
    brands: [],
    fields: [
      {
        key: "name",
        label: "Name",
        sortable: true,
      },
      {
        key: "email",
        label: "Email",
        sortable: true,
      },
      {
        key: "locations",
        label: "Access",
        sortable: false,
        class: "text-center",
      },
      {
        key: "restrictNotifications",
        label: "Notifications",
        sortable: true,
        class: "text-center",
      },
      {
        key: "edit",
        label: "",
        sortable: false,
        class: "text-center",
      },
    ],
    invitedUsers: [],
    pendingInvitedUsers: [],
    totalRows: 0,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [load.fulfilled]: (state, action) => {
      if (action.payload && action.payload.length > 0) {
        state.brands = action.payload.map((b) => ({
          brand: b.brand,
          url: b.siteUrl
            ? b.siteUrl
            : b.embedUrl
            ? b.embedUrl
            : `https://www.donationscout.com/b/${_.kebabCase(b.brand)}`,
        }));
      }
      state.loading = false;
      return;
    },
    [load.pending]: (state, action) => {
      state.loading = true;
      return;
    },
    [load.rejected]: (state, action) => {
      state.loading = false;
      return;
    },
    [loadInvitedUsers.fulfilled]: (state, action) => {
      if (action.payload && action.payload.length > 0) {
        state.invitedUsers = action.payload;
        state.totalRows = action.payload.length;
      }
      state.loadingInvitedUsers = false;
      return;
    },
    [loadInvitedUsers.pending]: (state, action) => {
      state.loadingInvitedUsers = true;
      return;
    },
    [loadInvitedUsers.rejected]: (state, action) => {
      state.loadingInvitedUsers = false;
      return;
    },
    [loadPendingInvitedUsers.fulfilled]: (state, action) => {
      if (action.payload && action.payload.length > 0) {
        state.pendingInvitedUsers = action.payload.map((p) =>
          Object.assign({ ...p }, { pendingInvite: true })
        );
      }
      state.loadingPendingInvitedUsers = false;
      return;
    },
    [loadPendingInvitedUsers.pending]: (state, action) => {
      state.loadingPendingInvitedUsers = true;
      return;
    },
    [loadPendingInvitedUsers.rejected]: (state, action) => {
      state.loadingPendingInvitedUsers = false;
      return;
    },
  },
});

export const { setLoading } = profileSlice.actions;
export default profileSlice.reducer;
