import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAlgoliaKey, getAlgoliaIndex } from "../../services/algolia";

export const setAlgoliaToken = createAsyncThunk(
  "algolia/setAlgoliaToken",
  (_, { getState }) => {
    const { account } = getState();
    return account.currentUser.getIdToken();
  }
);

export const setAlgoliaKey = createAsyncThunk(
  "algolia/setAlgoliaKey",
  (_, { getState }) => {
    const { algolia } = getState();
    return getAlgoliaKey(algolia.token);
  }
);

export const setAlgoliaIndex = createAsyncThunk(
  "algolia/setAlgoliaIndex",
  ({index,sort}, { getState }) => {
    const { algolia } = getState();
    let mode =
      process.env.GATSBY_FIREBASE_CONFIG_PROJECT_ID.split("-").pop() ===
      "staging"
        ? "-staging"
        : "";
    return getAlgoliaIndex(`${index}${mode}${sort?'_'+sort:''}`, algolia.key);
  }
);

export const algoliaSlice = createSlice({
  name: "algolia",
  initialState: {
    loading: true,
    token: null,
    key: null,
    index: null
  },
  reducers: {
    setIndex: (state, action) => {
      state.index = action.payload;
    }
  },
  extraReducers: {
    [setAlgoliaToken.fulfilled]: (state, action) => {
      state.token = action.payload;
      return;
    },
    [setAlgoliaToken.pending]: (state, action) => {
      state.loading = true;
      return;
    },
    [setAlgoliaToken.rejected]: (state, action) => {
      state.token = null;
      return;
    },
    [setAlgoliaKey.fulfilled]: (state, action) => {
      state.key = action.payload;
      return;
    },
    [setAlgoliaKey.pending]: (state, action) => {
      state.loading = true;
      return;
    },
    [setAlgoliaKey.rejected]: (state, action) => {
      state.key = null;
      return;
    },
    [setAlgoliaIndex.fulfilled]: (state, action) => {
      state.index = action.payload;
      state.loading = false;
      return;
    },
    [setAlgoliaIndex.pending]: (state, action) => {
      state.loading = true;
      return;
    },
    [setAlgoliaIndex.rejected]: (state, action) => {
      state.index = null;
      return;
    },
  },
});
export const { setIndex } = algoliaSlice.actions;
export default algoliaSlice.reducer;