import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getCollection,
  getDocument
} from "../config";
import _ from "lodash";

export const loadTemplates = createAsyncThunk(
  "outreach/loadTemplates",
  async (_, { getState, rejectWithValue }) => {
    const { account } = getState();
    
    try {

      const rs = await getCollection("outreach_templates", {
        where: [
          [
            "userID","==",account.user.id
          ],
        ],
      });

      console.log(rs)

      return rs;

    } catch(e) {

      return rejectWithValue(e.message);

    }

  }
);

export const loadBrands = createAsyncThunk(
  "outreach/loadBrands",
  async (_, { getState, rejectWithValue }) => {
    const { account } = getState();
    const { user } = account;
    return getCollection("brands", {
      where: [
        [
          "users." + (user.parentUserID ? user.parentUserID : user.id),
          "==",
          true,
        ],
      ],
    });
  }
);

export const loadLocations = createAsyncThunk(
  "outreach/loadLocations",
  async (data, { getState, rejectWithValue }) => {
    
    const { account } = getState();
    const { user } = account;

    try {
      if (!data) return false;
      let brand = await getDocument("brands", data);
      if (_.isEmpty(brand)) return false;
      let locations = await getCollection("locations", {
        where: [
          ["brandID", "==", data],
          [
            "users." + (user.parentUserID ? user.parentUserID : user.id),
            "==",
            true,
          ],
        ],
      });
      locations = locations.filter(l=>!l.deleted&&!["Non Participating","Temporarily Closed","Scheduled","Referral"].includes(l.status));
      return { brand, locations };
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const loadOrgTypes = createAsyncThunk(
  "outreach/loadOrgTypes",
  async (data, { getState, rejectWithValue }) => {
    
    const { account } = getState();
    const { user } = account;

    try {
      let rs = [];
      rs = await getCollection("org_types", {where: [["userID", "==", user.uid]]});
      if(rs.length===0&&data) rs = await getCollection("org_types", {where: [["brandID", "==", data]]});
      if(rs.length===0) rs = await getCollection("org_types", {where: [["userID", "==", false],["brandID", "==", false]]});
      return rs;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const outreachSlice = createSlice({
  name: "outreach",
  initialState: {
    processing: false,
    loading: true,
    loadingError: null,
    loadingLocations: false,
    templates:[],
    orgTypes: [],
    loadingOrgTypes: false,
    optionsBrands: [],
    optionsLocations: [],
    locationsData: [],
    loadLocationsError: null
  },
  reducers: {
    
  },
  extraReducers: {
    [loadBrands.fulfilled]: (state, action) => {
      if (action.payload?.length) {
        var brandData = {};
        var optBrands = [];
        var br = [];
        let brands = action.payload;
        brands.forEach((brand) => {
          optBrands.push({ value: brand.id, label: brand.brand });
          brandData = {
            [".key"]: brand.id,
            brand: brand.brand,
            logo: brand.logo ? brand.logo : false,
            menu: brand.menu ? brand.menu : "",
            rules: brand.rules ? brand.rules : [],
            website: brand.website ? brand.website : "",
          };
          br.push(brandData);
        });
        state.optionsBrands = optBrands;
        state.brands = br;
        if (optBrands.length == 1) {
          state.brand = brandData;
        }
      }
      return;
    },
    [loadBrands.pending]: (state, action) => {
      return;
    },
    [loadBrands.rejected]: (state, action) => {
      return;
    },
    [loadLocations.fulfilled]: (state, action) => {
      if (action.payload.locations?.length) {
        let locations = action.payload.locations;
        var optLocations = [];
        var locat = [];
        locations.forEach((location) => {
          if (!location.deleted) {
            locat[location.id] = location;
            optLocations.push({
              value: location.id,
              label:
                (action.payload.brand.includeExternal && location.externalID
                  ? location.externalID + " - "
                  : "") + location.location,
            });
          }
        });
        state.optionsLocations = optLocations.sort((a, b) =>
          a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        );
        state.locationsData = locat;
      }else{
        state.optionsLocations = [];
        state.locationsData = [];
      }
      state.loadingLocations = false;
      return;
    },
    [loadLocations.pending]: (state, action) => {
      state.loadLocationsError = false;
      state.loadingLocations = true;
      return;
    },
    [loadLocations.rejected]: (state, action) => {
      state.loadLocationsError = action.payload;
      state.loadingLocations = false;
      return;
    },
    [loadOrgTypes.fulfilled]: (state, action) => {
      state.orgTypes = action.payload.sort((a, b)=>(a.sort - b.sort)).map(t=>t.type);
      state.loadingOrgTypes = false;
      return;
    },
    [loadOrgTypes.pending]: (state, action) => {
      state.loadingOrgTypes = true;
      return;
    },
    [loadOrgTypes.rejected]: (state, action) => {
      state.loadingOrgTypes = false;
      return;
    },
    [loadTemplates.fulfilled]: (state, action) => {
      state.templates = action.payload;
      state.loading = false;
      return;
    },
    [loadTemplates.pending]: (state, action) => {
      state.loading = true;
      return;
    },
    [loadTemplates.rejected]: (state, action) => {
      state.loadingError = action.payload;
      state.loading = false;
      return;
    }
  },
});

export const {
  
} = outreachSlice.actions;
export default outreachSlice.reducer;