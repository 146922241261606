import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {reservationStatus, toCamelCase, formatCurrency, constants} from "../../services/utilities";
import { updateDocument, getDocument, createDocument, getCollection, getCollectionDocs, timestamp, fieldDelete, fsBatch, fileList, fileDelete, docRef, storageRef, api } from "../config";
import moment from "moment-timezone";

const endpoint = 'https://us-central1-'+process.env.GATSBY_FIREBASE_CONFIG_PROJECT_ID+'.cloudfunctions.net/requestPostmark/';

export const loadReservation = createAsyncThunk(
    "reservation/loadReservation",
    async (payload, { rejectWithValue }) => {
        try{
            let rez = await getDocument("event_reservations", payload, [
                {
                  key: "eventUserID",
                  collection: "users_public",
                  field: "eventUserPublic",
                },
                {
                  key: "id",
                  collection: "event_reservation_payments",
                  field: "erp",
                },
                {
                  key: "userID",
                  collection: "users",
                  field: "user",
                },
                {
                  key: "orgID",
                  collection: "user_organizations",
                  field: "org",
                },
                {
                  key: "eventID",
                  collection: "events",
                  field: "event",
                },
                {
                  key: "locationID",
                  collection: "locations",
                  field: "location",
                },
                {
                  key: "brandID",
                  collection: "brands",
                  field: "brand",
                },
            ]);
            return rez;
        }catch(e){
            return rejectWithValue(e.message);
        }
    }
);

export const getPurchases = createAsyncThunk(
  "reservation/getPurchases",
  async (_, { getState, rejectWithValue }) => {
      try{
        const {reservation} = getState();
        let rs = await getCollection(`event_reservations/${reservation.reservation.id}/purchases`, {order:['created','desc']});
        return rs;
      }catch(e){
          return rejectWithValue(e.message);
      }
  }
);

export const loadEmail = createAsyncThunk(
  "reservation/loadEmail",
  async (MessageID, { getState, rejectWithValue }) => {
      
    try{

      const {account} = getState();  
      let token = await account.currentUser.getIdToken();
      let res = await fetch(endpoint+'detail?message_id='+MessageID, {
          headers: { Authorization: 'Bearer ' + token }
      });
      let message = await res.json();
      return message;
    
    }catch(e){

      return rejectWithValue(e.message);

    }
  }
);

export const resendEmail = createAsyncThunk(
  "reservation/resendEmail",
  async (_, { getState, rejectWithValue }) => {
      
    try{

      const {reservation,account} = getState();
      
      const Message = reservation.emailToResend;
      let token = await account.currentUser.getIdToken();
      let res = await fetch(endpoint+'resend?message_id='+Message.MessageID+'&recipient='+Message.Recipient, {
          headers: { Authorization: 'Bearer ' + token }
      });
      if(res.status==500){
        let err = await res.text();
        throw new Error(err);
      }else{
        let m = await res.json();
        return m;
      }

    }catch(e){

      return rejectWithValue(e.message);

    }
  }
);

export const loadEmails = createAsyncThunk(
  "reservation/loadEmails",
  async (_, { getState, rejectWithValue }) => {
      
    try{

      const {reservation,account} = getState();
      
      let token = await account.currentUser.getIdToken();

      let res = await fetch(endpoint+'outbound?reservation_id='+reservation.reservation.id, {
          headers: { Authorization: 'Bearer ' + token }
      });
      
      let emails = await res.json();
      
      return emails;

    }catch(e){

      return rejectWithValue(e.message);

    }
  }
);

export const refreshCreative = createAsyncThunk(
  "reservation/refreshCreative",
  async (_, { getState, rejectWithValue }) => {
      
    try{

        const {reservation} = getState();
          
        var batch = fsBatch();

        let docs = null;

        let existingFiles = await fileList(storageRef('cf/r/'+reservation.reservation.id));
        let promises = [];
        if(existingFiles.items.length>0){
          existingFiles.items.forEach(f=>promises.push(fileDelete(f)));
        }
        await Promise.all(promises);

        docs = await getCollectionDocs("brand_creative",{
          where : [
            ['reservationID','==',reservation.reservation.id]
          ]
        });
        docs.forEach((doc)=>{
          batch.delete(doc.ref);
        });

        await batch.commit();

        let dispatchData = {
            reservationID:reservation.reservation.id
        };
        await api.brandCreativeRefresh(dispatchData)

        return;

      }catch(e){

          return rejectWithValue(e.message);

      }
  }
);

export const changeStatus = createAsyncThunk(
  "reservation/changeStatus",
  async (payload, { getState, rejectWithValue }) => {
      try{
        const {account,reservation} = getState();
        
        let status = toCamelCase(payload.status);

        let data = {
          reservationID:reservation.reservation.id,
          ['updateBy']:account.user.parentUserID?account.user.parentUserID:account.user.uid
        };

        if((status=='cancelled'||status=='denied')){
          data['reason']=payload.reason||'';
        }

        switch(status){
          case'cancelled':
            await api.eventReservationCancel(data);
          break;
          case'denied':
            await api.eventReservationDeny(data);
          break;
          case'approved':
            await api.eventReservationApprove(data);
          break;
          case'missingInfo':
            await api.eventReservationMissingInfo(data);
          break;
        }

        return {
          status : {[status]:true},
          [status+'By']:account.user.parentUserID?account.user.parentUserID:account.user.uid
        };
      }catch(e){
          return rejectWithValue(e.message);
      }
  }
);

export const saveResults = createAsyncThunk(
  "reservation/saveResults",
  async (payload, { getState, rejectWithValue }) => {
      try{
        const {reservation} = getState();
        
        let data = {...payload};

        if(reservation.messages.filter(m=>m.message==='Results Posted').length>0){
          await api.eventReservationResultsUpdate({
            reservationID:reservation.reservation.id,
            updateBy:reservation.reservation.eventUserID,
            updateType:'results',
            updateFrom:{
              sales:'$'+formatCurrency(reservation.reservation.sales),
              donation:'$'+formatCurrency(reservation.reservation.donation),
              threshold:(reservation.reservation.thresholdNotMet?'No':'Yes')
            },
            updateTo:{
              sales:'$'+formatCurrency(data.sales),
              donation:'$'+formatCurrency(data.donation),
              threshold:(payload.thresholdNotMet?'No':'Yes')
            },
            ...data
          });
        }else{
          await api.eventReservationResults({
            reservationID:reservation.reservation.id,
            updateBy:reservation.reservation.eventUserID,
            ...data
          });
        }

        return {...payload};
      }catch(e){
        console.log(e.message)
          return rejectWithValue(e.message);
      }
  }
);

export const savePayment = createAsyncThunk(
  "reservation/savePayment",
  async (payload, { getState, rejectWithValue }) => {
      try{
        const {reservation} = getState();
        await api.apiEventReservationPayment({
          reservationID:reservation.reservation.id,
          updateBy:reservation.reservation.eventUserID,
          ...payload
        })
        return {...payload};
      }catch(e){
          return rejectWithValue(e.message);
      }
  }
);

export const changeDate = createAsyncThunk(
  "reservation/changeDate",
  async (payload, { getState, rejectWithValue }) => {
      try{
        const {account,reservation} = getState();
        
        await api.eventReservationReschedule({
          ...payload.updateData,
          reservationID:reservation.reservation.id,
          updateBy:(account.user.permissions.admin?reservation.reservation.eventUserID:(account.user.parentUserID?account.user.parentUserID:account.user.uid)),
          updateType:'date',
          updateFrom:payload.updateFrom,
          updateTo:payload.updateTo,
          reason:payload.reason
        });

        return payload;
      }catch(e){
          return rejectWithValue(e.message);
      }
  }
);

export const loadLocations = createAsyncThunk(
  "reservation/loadLocations",
  async (_, { getState, rejectWithValue }) => {
      try{
        const {account,reservation} = getState();
        
        let rs = await getCollection("locations", {
          where: [
            ["brandID", "==", reservation.reservation.brandID]
          ]
        });
        return rs.filter(l=>!l.deleted&&!["Non Participating","Temporarily Closed","Scheduled","Referral"].includes(l.status))
        .filter(l=>account.user.permissions.admin||l.users?.[account.user.parentUserID?account.user.parentUserID:account.user.uid])
        .map((l)=>Object.assign({
          value:l.id,
          label:(reservation.reservation.brand.includeExternal&&l.externalID?l.externalID + ' - ':'') + l.location,
          location:l.location,
          address1:l.address1,
          city:l.city,
          state:l.state,
          zip:l.zip,
          schedule:l.schedule,
          timezone:l.timezone
        }))
        .sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
      }catch(e){
          return rejectWithValue(e.message);
      }
  }
);

export const loadMessages = createAsyncThunk(
  "reservation/loadMessages",
  async (payload, { getState, rejectWithValue }) => {
      try{
        let rs = await getCollection("messages", {
          where: [
            ["reservationID", "==", payload]
          ],
          order:['date','desc']
        });
        return rs;
      }catch(e){
          return rejectWithValue(e.message);
      }
  }
);

export const changeLocation = createAsyncThunk(
  "reservation/changeLocation",
  async (payload, { getState, rejectWithValue }) => {
      try{
        const {account,reservation} = getState();
        
        let newLocation = {...payload.location};
        let updateFrom = {};
        
        ['location','address1','city','state','zip'].forEach(f=>updateFrom[f]=reservation.reservation.location[f]);

        let updateTo = {};
        ['location','address1','city','state','zip'].forEach(f=>updateTo[f]=newLocation[f]);

        let updateData = {};

        newLocation.id=newLocation.value;
        delete newLocation.value;

        if(!reservation.reservation.eventID){
          updateData.location = {...newLocation};
        }else{
          updateData.locationID = newLocation.id;
        }

        await api.eventReservationLocationUpdate({
          reservationID:reservation.reservation.id,
          updateBy:(account.user.permissions.admin?reservation.reservation.eventUserID:(account.user.parentUserID?account.user.parentUserID:account.user.uid)),
          updateType:'location',
          updateFrom:updateFrom,
          updateTo:updateTo,
          reason:payload.reason,
          ...updateData
        });

        return payload;
      }catch(e){
          return rejectWithValue(e.message);
      }
  }
);

export const changePayout = createAsyncThunk(
  "reservation/changePayout",
  async (payload, { getState, rejectWithValue }) => {
      try{

        const {account,reservation} = getState();
        
        let updateFrom = reservation.reservation.event.payout + '% of ' + reservation.reservation.event.payoutType;
        let updateTo = payload.payoutChanges.payout + '% of ' + payload.payoutChanges.payoutType;
        
        await api.eventReservationPayoutUpdate({
          reservationID:reservation.reservation.id,
          updateBy:(account.user.permissions.admin?reservation.reservation.eventUserID:(account.user.parentUserID?account.user.parentUserID:account.user.uid)),
          updateType:'payout',
          updateFrom:updateFrom,
          updateTo:updateTo,
          reason:payload.reason,
          payout:payload.payoutChanges.payout,
          payoutType:payload.payoutChanges.payoutType
        });

        return {
          ...reservation.reservation.event,
          payout:payload.payoutChanges.payout,
          payoutType:payload.payoutChanges.payoutType
        };

      }catch(e){
          
        return rejectWithValue(e.message);

      }
  }
);

export const changePaymentTo = createAsyncThunk(
  "reservation/changePaymentTo",
  async (payload, { getState, rejectWithValue }) => {
      try{
        const {reservation} = getState();
        await createDocument({entity:'event_reservation_payments',id:reservation.reservation.id,merge:true},payload.data);
        return payload.data;
      }catch(e){
          return rejectWithValue(e.message);
      }
  }
);

export const reservationSlice = createSlice({
    name: "reservation",
    initialState: {
      loading:true,
      loadingError:null,
      loadingMessages:true,
      messagesError:null,
      loadingEmails:true,
      loadEmailError:null,
      emailsError:null,
      emails:[],
      emailToResend:null,
      emailToLoad:null,
      reservation:{
        event:null,
        location:null
      },
      messages:[],
      editingDate:false,
      changingDate:false,
      changeDateError:null,
      editingLocation:false,
      changingLocation:false,
      changeLocationError:null,
      loadingLocations:false,
      locations:[],
      editingPayout:false,
      changingPayout:false,
      changePayoutError:null,
      editingPaymentTo:false,
      changingPaymentTo:false,
      changePaymentToError:null,
      activeModal:null,
      changeStatusText:'',
      changingStatus:false,
      changeStatusError:null,
      savingResults:false,
      savingResultsError:null,
      savingPayment:false,
      savingPaymentError:null,
      refreshingCreative:false,
      creativeError:null,
      purchases:[],
      purchasesError:null,
      loadingPurchases:true
    },
    reducers: {
      setEditingDate: (state,action) => {
        state.editingDate = action.payload;
      },
      setChangeDateError: (state,action) => {
        state.changeDateError = action.payload;
      },
      setEditingLocation: (state,action) => {
        state.editingLocation = action.payload;
      },
      setChangeLocationError: (state,action) => {
        state.changeLocationError = action.payload;
      },
      setEditingPayout: (state,action) => {
        state.editingPayout = action.payload;
      },
      setChangePayoutError: (state,action) => {
        state.changePayoutError = action.payload;
      },
      setEditingPaymentTo: (state,action) => {
        state.editingPaymentTo = action.payload;
      },
      setChangePaymentToError: (state,action) => {
        state.changePaymentToError = action.payload;
      },
      setActiveModal: (state,action) => {
        state.activeModal = action.payload;
      },
      setChangeStatusText: (state,action) => {
        state.changeStatusText = action.payload;
      },
      setChangeStatusError: (state,action) => {
        state.changeStatusError = action.payload;
      },
      setSavingResultsError: (state,action) => {
        state.savingResultsError = action.payload;
      },
      setSavingPaymentError: (state,action) => {
        state.savingPaymentError = action.payload;
      },
      setMessages: (state,action) => {
        state.messages = action.payload;
      },
      setEmailToResend: (state,action) => {
        state.emailToResend = action.payload;
      },
      setEmailToLoad: (state,action) => {
        state.emailToLoad = action.payload;
      },
      setAchFrom: (state,action) => {
        console.log(action.payload);
        state.reservation.filterStatus = 'Pending Check';
        state.reservation.achFrom = action.payload;
      }
    },
    extraReducers: {
      [loadReservation.fulfilled]: (state, action) => {
        if(action.payload) {

          let shortDate = moment(action.payload.eventDate,constants.dayFormat).format('ll');

          if(action.payload.multiDay) {
        
            const startDate = moment(action.payload.eventDate.split('-').shift(),constants.dayFormat);
            const endDate = moment(action.payload.eventDate.split('-').pop(),constants.dayFormat);

            shortDate = startDate.format('ll') + ' to ' + endDate.format('ll');

          }

          state.reservation = {
            ...action.payload,
            shortDate:shortDate,
            filterStatus:reservationStatus(action.payload)
          };
          state.reservation.paymentTo = action.payload.erp?.paymentTo;
          state.reservation.paymentFrom = action.payload.erp?.paymentFrom;
          state.reservation.achFrom = action.payload.erp?.achFrom;
          state.reservation.org = state.reservation.org&&state.reservation.org.id!==state.reservation.id?state.reservation.org:(state.reservation.user.org?state.reservation.user.org:null);
          state.reservation.timezone = state.reservation.location&&state.reservation.location.timezone?state.reservation.location.timezone:moment.tz.guess();
          state.reservation.allDay = ('allDay' in state.reservation) ? state.reservation.allDay : ('allDay' in state.reservation.event && !state.reservation.starts) ? state.reservation.event.allDay : false;
          state.reservation.timeStarts = state.reservation.starts?state.reservation.starts:state.reservation.event.starts;
          state.reservation.timeEnds = state.reservation.ends?state.reservation.ends:state.reservation.event.ends;
          state.reservation.hours = state.reservation.allDay?'All Day':moment(state.reservation.timeStarts).format('h:mm a') + ' to ' + moment(state.reservation.timeEnds).format('h:mm a z');
          state.reservation.isAfter = moment().isAfter(moment.unix(state.reservation.eventDay));
        } else {
          state.loadingError = "Reservation does not exist."
        }
        state.loading = false;
        return;
      },
      [loadReservation.pending]: (state, action) => {
        state.loading = true;
        return;
      },
      [loadReservation.rejected]: (state, action) => {
        state.loadingError = action.payload;
        state.loading = false;
        return;
      },
      [changeDate.fulfilled]: (state, action) => {
        state.reservation.eventDate = action.payload.updateData.eventDate;
        state.reservation.eventDay = action.payload.updateData.eventDay;
        state.reservation.allDay = action.payload.updateData.allDay;
        state.reservation.timeStarts = action.payload.updateData.starts;
        state.reservation.timeEnds = action.payload.updateData.ends;
        state.reservation.shortDate = moment(action.payload.updateData.eventDate,constants.dayFormat).format('ll');
        state.reservation.hours = action.payload.updateData.allDay?'All Day':moment(action.payload.updateData.starts).format('h:mm a') + ' to ' + moment(action.payload.updateData.ends).format('h:mm a z');
        if(state.reservation.eventID){
          state.reservation.event={
            ...state.reservation.event,
            starts:action.payload.updateData.starts,
            ends:action.payload.updateData.ends,
            allDay:action.payload.updateData.allDay
          };
        }
        state.changingDate = false;
        state.editingDate = false;
        return;
      },
      [changeDate.pending]: (state, action) => {
        state.changeDateError = null;
        state.changingDate = true;
        return;
      },
      [changeDate.rejected]: (state, action) => {
        state.changeDateError = action.payload;
        state.changingDate = false;
        return;
      },
      [changeLocation.fulfilled]: (state, action) => {
        state.reservation.location={...action.payload.location};
        if(!state.reservation.eventID){
          state.reservation.event={
            ...state.reservation.event,
            location:{...action.payload.location}
          };
        }
        state.changingLocation = false;
        state.editingLocation = false;
        return;
      },
      [changeLocation.pending]: (state, action) => {
        state.changeLocationError = null;
        state.changingLocation = true;
        return;
      },
      [changeLocation.rejected]: (state, action) => {
        state.changeLocationError = action.payload;
        state.changingLocation = false;
        return;
      },
      [loadLocations.fulfilled]: (state, action) => {
        state.locations = action.payload;
        state.loadingLocations = false;
        return;
      },
      [loadLocations.pending]: (state, action) => {
        state.loadingLocations = true;
        return;
      },
      [loadLocations.rejected]: (state, action) => {
        state.changeLocationError = action.payload;
        state.loadingLocations = false;
        return;
      },
      [changePayout.fulfilled]: (state, action) => {
        state.reservation.event={...action.payload};
        state.changingPayout = false;
        state.editingPayout = false;
        return;
      },
      [changePayout.pending]: (state, action) => {
        state.changePayoutError = null;
        state.changingPayout = true;
        return;
      },
      [changePayout.rejected]: (state, action) => {
        state.changePayoutError = action.payload;
        state.changingPayout = false;
        return;
      },
      [changePaymentTo.fulfilled]: (state, action) => {
        state.reservation.paymentTo={...action.payload.paymentTo};
        state.changingPaymentTo = false;
        state.editingPaymentTo = false;
        return;
      },
      [changePaymentTo.pending]: (state, action) => {
        state.changePaymentToError = null;
        state.changingPaymentTo = true;
        return;
      },
      [changePaymentTo.rejected]: (state, action) => {
        state.changePaymentToError = action.payload;
        state.changingPaymentTo = false;
        return;
      },
      [changeStatus.fulfilled]: (state, action) => {
        state.reservation = {
          ...state.reservation,
          ...action.payload
        };
        state.reservation.filterStatus = reservationStatus({...state.reservation});
        state.changingStatus = false;
        state.activeModal = null;
        return;
      },
      [changeStatus.pending]: (state, action) => {
        state.changeStatusError = null;
        state.changingStatus = true;
        return;
      },
      [changeStatus.rejected]: (state, action) => {
        state.changeStatusError = action.payload;
        state.changingStatus = false;
        return;
      },
      [saveResults.fulfilled]: (state, action) => {
        state.reservation = {
          ...state.reservation,
          ...action.payload,
          filterStatus:(action.payload.thresholdNotMet?'Completed':'Pending Payment')
        };
        state.savingResults = false;
        return;
      },
      [saveResults.pending]: (state, action) => {
        state.savingResultsError = null;
        state.savingResults = true;
        return;
      },
      [saveResults.rejected]: (state, action) => {
        state.savingResultsError = action.payload;
        state.savingResults = false;
        return;
      },
      [savePayment.fulfilled]: (state, action) => {
        state.reservation.payment = true;
        state.reservation.filterStatus = 'Completed';
        state.savingPayment = false;
        return;
      },
      [savePayment.pending]: (state, action) => {
        state.savingPaymentError = null;
        state.savingPayment = true;
        return;
      },
      [savePayment.rejected]: (state, action) => {
        state.savingPaymentError = action.payload;
        state.savingPayment = false;
        return;
      },
      [loadMessages.fulfilled]: (state, action) => {
        state.messages = action.payload;
        state.loadingMessages=false;
        return;
      },
      [loadMessages.pending]: (state, action) => {
        state.messagesError = null;
        state.loadingMessages=true;
        return;
      },
      [loadMessages.rejected]: (state, action) => {
        state.messagesError = action.payload;
        state.loadingMessages=false;
        return;
      },
      [refreshCreative.fulfilled]: (state, action) => {
        state.refreshingCreative=false;
        return;
      },
      [refreshCreative.pending]: (state, action) => {
        state.creativeError = null;
        state.refreshingCreative=true;
        return;
      },
      [refreshCreative.rejected]: (state, action) => {
        state.creativeError = action.payload;
        state.refreshingCreative=false;
        return;
      },
      [loadEmails.fulfilled]: (state, action) => {
        state.emails = action.payload;
        state.loadingEmails=false;
        return;
      },
      [loadEmails.pending]: (state, action) => {
        state.emailsError = null;
        state.loadingEmails=true;
        return;
      },
      [loadEmails.rejected]: (state, action) => {
        state.emailsError = action.payload;
        state.loadingEmails=false;
        return;
      },
      [resendEmail.fulfilled]: (state, action) => {
        let m = action.payload;
        state.emails.unshift({
          MessageID:m.MessageID,
          Type:'Processed',
          Recipient:state.emailToResend.Recipient,
          Subject:state.emailToResend.Subject,
          ReceivedAt:moment(m.SubmittedAt).unix(),
        });
        state.emailToResend=null;
        state.loadingEmails=false;
        return;
      },
      [resendEmail.pending]: (state, action) => {
        state.emailsError = null;
        return;
      },
      [resendEmail.rejected]: (state, action) => {
        state.emailsError = action.payload;
        return;
      },
      [loadEmail.fulfilled]: (state, action) => {
        state.emailToLoad=action.payload;
        state.loadingEmail=false;
        return;
      },
      [loadEmail.pending]: (state, action) => {
        state.emailToLoad = null;
        state.loadEmailError = null;
        state.loadingEmail=true;
        return;
      },
      [loadEmail.rejected]: (state, action) => {
        state.emailToLoad = null;
        state.loadEmailError = action.payload;
        state.loadingEmail=false;
        return;
      },
      [getPurchases.fulfilled]: (state, action) => {
        state.purchases = action.payload;
        state.loadingPurchases=false;
        return;
      },
      [getPurchases.pending]: (state, action) => {
        state.purchasesError = null;
        state.loadingPurchases=true;
        return;
      },
      [getPurchases.rejected]: (state, action) => {
        console.log(action.payload)
        state.purchasesError = action.payload;
        state.loadingPurchases=false;
        return;
      }
    }
  });
  
  export default reservationSlice.reducer;
  export const {
    setEditingDate,
    setChangeDateError,
    setEditingLocation,
    setChangeLocationError,
    setChangePayoutError,
    setEditingPayout,
    setEditingPaymentTo,
    setChangePaymentToError,
    setActiveModal,
    setChangeStatusText,
    setChangeStatusError,
    setSavingResultsError,
    setSavingPaymentError,
    setMessages,
    setEmailToResend,
    setEmailToLoad,
    setAchFrom
  } = reservationSlice.actions;
  