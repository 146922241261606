import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createDocument, updateDocument, getCollection, getDocument } from "../config";
import _ from "lodash";

export const locationFields = [
    {sortable:true,label:'Location',key:'location'},
    {sortable:true,label:'Address 1',key:'address1'},
    {sortable:true,label:'Address 2',key:'address2'},
    {sortable:true,label:'City',key:'city'},
    {sortable:true,label:'State',key:'state'},
    {sortable:true,label:'Zip',key:'zip'}
];

const applyFilter = (filter,arr) => {
    return arr.filter(l=>{
        let s = filter.toLowerCase();
        if(s.length===0) return true;
        if(
            l.location?.toLowerCase().indexOf(s)>-1
            ||
            l.address1?.toLowerCase().indexOf(s)>-1
            ||
            l.address2?.toLowerCase().indexOf(s)>-1
            ||
            l.city?.toLowerCase().indexOf(s)>-1
            ||
            l.state?.toLowerCase().indexOf(s)>-1
            ||
            l.zip?.toLowerCase().indexOf(s)>-1
        ){
            return true;
        }
        return false;
    });
}
const applySorting = (order,orderBy,arr)=>{
    return arr.sort((a, b) => {
        if(orderBy==='ascending'){
            if (a[order] < b[order]) {
                return -1;
            }
            if (a[order] > b[order]) {
                return 1;
            }
        }else{
            if (a[order] > b[order]) {
                return -1;
            }
            if (a[order] < b[order]) {
                return 1;
            }
        }
        
        return 0;
    });
}
const applyPaging = (limit,page,arr) => {
    return arr.filter((_,i)=>{
        let offset = (limit*(page+1));
        return (i>=(offset-limit)&&i<offset);
    });
}


export const newLocation = createAsyncThunk(
    "brand/newLocation",
    async (location, { getState, rejectWithValue }) => {
        try{
            const {account,brand} = getState();

            let data = {
                ...location,
                brandID:brand.brand.id
            };

            data.users = {[account.user.uid]:true};

            let rs = await createDocument({entity:'locations'},data);

            return rs;
        }catch(e){
            return rejectWithValue(e.message);
        }
    }
);
    

export const exportLocations = createAsyncThunk(
    "brand/exportLocations",
    async (_, { getState, rejectWithValue }) => {
        try{
            const {brand} = getState();
            
            let tableHeaders = locationFields.map(a=>a.label.replace('#','Number'));
            let tableFields = locationFields.map(a=>a.key)

            let tableValues = brand.brand.locations.map(item=>tableFields.map(field=>{
                return '"' + (item[field]?item[field].toString().replace(/[^\x00-\x7F]/g, '').replace('#',''):'') + '"';
            }));

            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += tableHeaders.join(',') + '\n';
            csvContent += tableValues.map(row=>row.map(cell=>cell).join(',')).join('\n');

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "export.csv");
            link.click();
        }catch(e){
            return rejectWithValue(e.message);
        }
    }
);

export const loadBrand = createAsyncThunk(
    "brand/loadBrand",
    async (id, { getState, rejectWithValue }) => {
        try{
            
            const {account} = getState();

            let brand = await getDocument('brands',id);

            let rsLocations = await getCollection('locations',{
                where:[
                    ['brandID','==',id],
                    ['users.'+account.user.uid,'==',true]
                ]
            });

            let locations = [];

            for (let i = 0; i < rsLocations.length; i++) {
                let location = rsLocations[i];
                if(
                    !location.deleted
                    &&
                    ![
                        "Non Participating",
                        "Temporarily Closed",
                        "Scheduled",
                        "Referral"
                    ].includes(location.status)
                ) locations.push({...location});
            }

            return {
                ...brand,
                locations:locations
            };
        }catch(e){
            return rejectWithValue(e.message);
        }
    }
);
export const filterLocations = createAsyncThunk(
    "brand/filterLocations",
    async (filter, { getState, rejectWithValue }) => {
        try{
            const {brand,pager} = getState();
            let locations = [...brand.brand.locations];
            locations = applyFilter(filter,locations);
            let pages = Math.ceil(locations.length/brand.limit);
            locations = applySorting(brand.locationOrder,brand.locationOrderBy,locations);
            locations = applyPaging(brand.limit,pager.page,locations);
            return {
                pages:pages,
                filter:filter,
                locations:locations
            };
        }catch(e){
            return rejectWithValue(e.message);
        }
    }
);
export const setLimit = createAsyncThunk(
    "brand/setLimit",
    async (limit, { getState, rejectWithValue }) => {
        try{
            const {brand,pager} = getState();
            let locations = [...brand.brand.locations];
            locations = applyFilter(brand.locationFilter,locations);
            let pages = Math.ceil(locations.length/limit);
            locations = applySorting(brand.locationOrder,brand.locationOrderBy,locations);
            locations = applyPaging(limit,pager.page,locations);
            return {
                pages:pages,
                limit:limit,
                locations:locations
            };
        }catch(e){
            return rejectWithValue(e.message);
        }
    }
);
export const sortLocations = createAsyncThunk(
    "brand/sortLocations",
    async (sort, { getState, rejectWithValue }) => {
        try{
            const {brand,pager} = getState();
            let orderBy = brand.locationOrder===sort?brand.locationOrderBy==='descending'?'ascending':'descending':'ascending';
            let locations = [...brand.brand.locations];
            locations = applyFilter(brand.locationFilter,locations);
            locations = applySorting(sort,orderBy,locations);
            locations = applyPaging(brand.limit,pager.page,locations);
            return {
                locationOrderBy:orderBy,
                locationOrder:sort,
                locations:locations
            };
        }catch(e){
            return rejectWithValue(e.message);
        }
    }
);

export const removeLocation = createAsyncThunk(
    "brand/removeLocation",
    async (id, { rejectWithValue }) => {
        try{
            await updateDocument({entity:'locations',id:id},{deleted:true});
            return id;
        }catch(e){
            return rejectWithValue(e.message);
        }
    }
);

export const updateLocationContacts = createAsyncThunk(
    "brand/updateLocationContacts",
    async (contacts, { getState, rejectWithValue }) => {
        try{
            const {brand} = getState();
            await updateDocument({entity:'locations',id:brand.selectedLocation.id},{contacts:contacts});
            return contacts;
        }catch(e){
            return rejectWithValue(e.message);
        }
    }
);

export const brandSlice = createSlice({
    name: "brand",
    initialState: {
        loading:true,
        loadingError:null,
        sorting:false,
        sortingError:null,
        exporting:false,
        exportingError:null,
        brand:null,
        locations:[],
        locationOrder:'location',
        locationOrderBy:'ascending',
        locationFilter:'',
        pages:0,
        limit:50,
        activeModal:null,
        adding:false,
        addingError:null,
        selectedLocation:null,
        updatingContactsError:null
    },
    reducers: {
        setActiveModal: (state,action) => {
            state.activeModal = action.payload;
        },
        setSelectedLocation: (state,action) => {
            state.selectedLocation = action.payload;
        },
        updateLocation: (state,action) => {
            state.brand.locations = state.brand.locations.map(l=>l.id===action.payload.id?action.payload:l);
            state.locations = state.locations.map(l=>l.id===action.payload.id?action.payload:l);
            state.activeModal = null;
            state.selectedLocation = null;
        }
    },
    extraReducers: {
        [loadBrand.fulfilled]: (state, action) => {
            state.brand = action.payload;
            state.pages = Math.ceil(action.payload.locations.length/state.limit);
            let l = [...action.payload.locations];
            l = applySorting('location','ascending',l);
            l = applyPaging(50,0,l);
            state.locations = [...l];
            state.loading = false;
            return;
        },
        [loadBrand.pending]: (state, action) => {
            state.loading = true;
            return;
        },
        [loadBrand.rejected]: (state, action) => {
            state.loadingError = action.payload;
            state.loading = false;
            return;
        },
        [sortLocations.fulfilled]: (state, action) => {
            state.locationOrder = action.payload.locationOrder;
            state.locationOrderBy = action.payload.locationOrderBy;
            state.locations = action.payload.locations;
            state.sorting = false;
            return;
        },
        [sortLocations.pending]: (state, action) => {
            state.sorting = true;
            return;
        },
        [sortLocations.rejected]: (state, action) => {
            state.sortingError = action.payload;
            state.sorting = false;
            return;
        },
        [removeLocation.fulfilled]: (state, action) => {
            state.brand.locations = state.brand.locations.filter(l=>l.id!==action.payload);
            state.locations = state.locations.filter(l=>l.id!==action.payload);
            state.removing = false;
            return;
        },
        [removeLocation.pending]: (state, action) => {
            state.removing = true;
            return;
        },
        [removeLocation.rejected]: (state, action) => {
            state.removing = false;
            return;
        },
        [setLimit.fulfilled]: (state, action) => {
            state.limit = action.payload.limit;
            state.pages = action.payload.pages;
            state.locations = action.payload.locations;
            return;
        },
        [filterLocations.fulfilled]: (state, action) => {
            state.pages = action.payload.pages;
            state.locationFilter = action.payload.filter;
            state.locations = action.payload.locations;
            return;
        },
        [exportLocations.fulfilled]: (state, action) => {
            state.exporting = false;
            return;
        },
        [exportLocations.pending]: (state, action) => {
            state.exportingError = null;
            state.exporting = true;
            return;
        },
        [exportLocations.rejected]: (state, action) => {
            state.exportingError = action.payload;
            state.exporting = false;
            return;
        },
        [newLocation.fulfilled]: (state, action) => {
            state.brand.locations.unshift({...action.payload});
            state.locations.unshift({...action.payload});
            state.adding = false;
            return;
        },
        [newLocation.pending]: (state, action) => {
            state.addingError = null;
            state.adding = true;
            return;
        },
        [newLocation.rejected]: (state, action) => {
            state.addingError = action.payload;
            state.adding = false;
            return;
        },
        [updateLocationContacts.fulfilled]: (state, action) => {
            state.brand.locations = state.brand.locations.map(l=>l.id===state.selectedLocation.id?Object.assign({...l},{contacts:action.payload}):l);
            state.locations = state.locations.map(l=>l.id===state.selectedLocation.id?Object.assign({...l},{contacts:action.payload}):l);
            return;
        },
        [updateLocationContacts.pending]: (state, action) => {
            state.updatingContactsError = null;
            return;
        },
        [updateLocationContacts.rejected]: (state, action) => {
            state.updatingContactsError = action.payload;
            return;
        }
    },
});

export const { 
    setActiveModal,
    setSelectedLocation,
    updateLocation
} = brandSlice.actions;
export default brandSlice.reducer;
