import { createSlice } from "@reduxjs/toolkit";

export const pagerSlice = createSlice({
  name: "pager",
  initialState: {
    page:0,
    pages:0,
  },
  reducers: {
    setPage: (state, action) => {
        state.page = action.payload;
    },
    setPages: (state, action) => {
        state.pages = action.payload;
    }
  },
  extraReducers: {},
});

export const {
    setPage,
    setPages
} = pagerSlice.actions;
export default pagerSlice.reducer;
