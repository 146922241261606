import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createDocument, getCollection, getDocument, deleteDocument, storageRef, fileURL, fileMeta, snapshot, api } from "../config";
import _ from "lodash";

export const loadFile = createAsyncThunk(
    "reservation/loadFile",
    async (_, { getState, rejectWithValue }) => {
        try{
            const {creative} = getState();

            let ref = storageRef(creative.file.fullPath);
            let previewUrl = await fileURL(ref);
            let meta = await fileMeta(ref);
            
            let doc = null;
            if(creative.file.canEditCaptions){
                let rs = await getCollection('brand_creative',{
                    where:[
                        ['fullPath','==',meta.fullPath]
                    ]
                });
                if(rs.length>0) doc = rs[0];
            }else{
                doc = await getDocument('brand_creative', meta.generation);
            }

            if(!creative.file.canEditCaptions&&doc) {
                ref = storageRef(doc.fullPath);
                previewUrl = await fileURL(ref);
            }

            return {
                meta:meta,
                previewUrl:previewUrl,
                doc:doc
            };
        }catch(e){
            return rejectWithValue(e.message);
        }
    }
);

export const deleteFile = createAsyncThunk(
    "reservation/deleteFile",
    async (_, { getState, rejectWithValue }) => {
        try{
            const {creative} = getState();

            let ref = storageRef(creative.file.fullPath);
            let meta = await fileMeta(ref);
            
            let doc = null;
            if(creative.file.canEditCaptions){
                let rs = await getCollection('brand_creative',{
                    where:[
                        ['fullPath','==',meta.fullPath]
                    ]
                });
                if(rs.length>0) doc = rs[0];
            }else{
                doc = await getDocument('brand_creative', meta.generation);
            }

            await deleteDocument('brand_creative',doc.id);

            return;
        }catch(e){
            return rejectWithValue(e.message);
        }
    }
);

export const processCreative = createAsyncThunk(
    "reservation/processCreative",
    async (doc, { rejectWithValue }) => {
        try{
            
            let previewUrl = await fileURL(storageRef(doc.data().fullPath));
            
            return {
                id:doc.id,
                previewUrl:previewUrl,
                ...doc.data()
            };

        }catch(e){

            return rejectWithValue(e.message);

        }
    }
);

export const saveCaptions = createAsyncThunk(
    "reservation/saveCaptions",
    async (_, { getState, rejectWithValue, dispatch }) => {
        try{
            const {creative,account} = getState();

            let path = creative.meta.fullPath.split('/');
            let brandID = null;
            let eventID = null;
            let userID = null;
            let reservationID = null;
            switch(path[1]){
                case 'b':
                    if(account.user.permissions.admin) brandID = path[2];
                    else userID = path[3];
                break;
                case 'e':
                    eventID = path[2];
                break;
                case 'r':
                    reservationID = path[2];
                break;
            }
            let data = {
                sourcePath:(creative.file.canEditCaptions?creative.sourcePath:creative.file.fullPath),
                bucket:creative.file.bucket,
                fullPath:(creative.file.canEditCaptions?creative.file.fullPath:'cfp/'+creative.file.folder+'/'+creative.file.name),
                name:creative.file.name,
                size:creative.file.size,
                timeCreated:creative.file.timeCreated,
                updated:creative.file.updated,
                contentType:creative.file.contentType,
                captions:creative.captions,
                qrcodes:creative.qrcodes,
                status:'process'
            };
            if(creative.parentID) data.parentID = creative.parentID;
            if(brandID) data['brandID'] = brandID;
            if(eventID) data['eventID'] = eventID;
            if(userID) data['userID'] = userID;
            if(reservationID) data['reservationID'] = reservationID;

            let creativeDocument = await getDocument('brand_creative',creative.id);
            if(!creativeDocument) creativeDocument = await createDocument(
                {
                    entity: "brand_creative",
                    id: creative.id,
                    merge: creative.qrcodes,
                },
                data
            );
            await api.brandCreativeUpdate({
                brandCreativeID: creative.id,
                captions: creative.captions,
                qrcodes: creative.qrcodes
            });
            let listener = snapshot('brand_creative',creative.id,(doc)=>{
                let data = doc.data();
                if(data.status=='complete'){
                    listener();
                    dispatch(processCreative(doc));
                }
            },{includeMetadataChanges:true});
            return;
        }catch(e){
            return rejectWithValue(e.message);
        }
    }
);

export const creativeSlice = createSlice({
    name: "creative",
    initialState: {
        loading:true,
        loadingError:null,
        saving:false,
        savingError:null,
        processing:false,
        id:null,
        sourcePath:null,
        parentID:null,
        file:null,
        meta:null,
        previewUrl:null,
        captions:[],
        qrcodes:[]

    },
    reducers: {
        setFile: (state, action) => {
            state.file = action.payload;
        },
        removeCaption: (state, action) => {
            state.captions=state.captions.filter(c=>c.id!==action.payload);
        },
        removeQRCode: (state, action) => {
            state.qrcodes=state.qrcodes.filter(c=>c.id!==action.payload);
        },
        setCaptions: (state, action) => {
            state.captions = state.captions.map(caption=>{
                if(caption.id===action.payload.id) return action.payload;
                return caption;
            })
        },
        setQRCodes: (state, action) => {
            state.qrcodes = state.qrcodes.map(code=>{
                if(code.id===action.payload.id) return action.payload;
                return code;
            })
        }
    },
    extraReducers: {
        [loadFile.fulfilled]: (state, action) => {
            const {meta,doc,previewUrl} = action.payload;
            state.id=doc?doc.id:meta.generation;
            state.meta=meta;
            state.previewUrl=previewUrl;
            if(doc&&doc.captions) state.captions=doc.captions.map(c=>{
                return {...c,lineHeight:(c.lineHeight?c.lineHeight:100)};
            });
            else state.captions=[];
            if(doc&&doc.qrcodes) state.qrcodes=doc.qrcodes;
            else state.qrcodes=[];
            state.sourcePath = doc?.sourcePath;
            state.parentID = doc?.parentID;
            state.loading = false;
            return;
        },
        [loadFile.pending]: (state, action) => {
            state.loading = true;
            return;
        },
        [loadFile.rejected]: (state, action) => {
            state.loadingError = action.payload;
            state.loading = false;
            return;
        },
        [saveCaptions.fulfilled]: (state, action) => {
            state.saving = false;
            return;
        },
        [saveCaptions.pending]: (state, action) => {
            state.savingError = null;
            state.saving = true;
            state.processing = true;
            return;
        },
        [saveCaptions.rejected]: (state, action) => {
            state.savingError = action.payload;
            state.saving = false;
            state.processing = false;
            return;
        },
        [processCreative.fulfilled]: (state, action) => {
            state.id=action.payload.id;
            state.previewUrl=action.payload.previewUrl;
            state.processing = false;
            return;
        },
        [processCreative.pending]: (state, action) => {
            state.savingError = null;
            state.processing = true;
            return;
        },
        [processCreative.rejected]: (state, action) => {
            state.savingError = action.payload;
            state.processing = false;
            return;
        }
    },
});

export const { 
    setFile,
    removeCaption,
    setCaptions,
    removeQRCode,
    setQRCodes
} = creativeSlice.actions;
export default creativeSlice.reducer;
