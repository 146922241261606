import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  updateDocument,
  getCollection,
  getDocument,
  createDocument
} from "../config";
import _ from "lodash";
import moment from "moment";
import { assignDateFilters, convertDateTZ } from "../../services/utilities";

export const loadEventTypes = createAsyncThunk(
  "eventTypes/loadEventTypes",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { account } = getState();

      let user = account.user;

      let rs = await getCollection("events", {
        where: [
          ["deleted", "==", false],
          ["userID", "==", user.parentUserID ? user.parentUserID : user.id],
        ],
        joins: [
          {
            key: "brandID",
            collection: "brands",
            field: "brand",
          },
        ],
      });
      return rs;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const deleteEventType = createAsyncThunk(
  "eventTypes/deleteEventType",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { eventTypes } = getState();
      let rs = await updateDocument(
        {
          entity: "events",
          id: eventTypes.event.id,
        },
        {
          deleted: true,
        }
      );
      return;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const removeDay = createAsyncThunk(
  "eventTypes/removeDay",
  async (removeDayAllLocations, { getState, rejectWithValue }) => {
    try {
      const { eventTypes } = getState();

      let e = { ...eventTypes.selectedLocationDay.event };

      let removedDays = { ...e.removedDays };

      if (!removedDays[eventTypes.selectedLocationDay.selectedDay.day])
        removedDays[eventTypes.selectedLocationDay.selectedDay.day] = {};

      if (removeDayAllLocations) {
        eventTypes.selectedLocationDay.locations.forEach((l) => {
          removedDays[eventTypes.selectedLocationDay.selectedDay.day] =
            Object.assign(
              {
                ...removedDays[
                  eventTypes.selectedLocationDay.selectedDay.day
                ],
              },
              { [l]: true }
            );
        });
      } else {
        removedDays[eventTypes.selectedLocationDay.selectedDay.day] =
          Object.assign(
            {
              ...removedDays[eventTypes.selectedLocationDay.selectedDay.day],
            },
            { [eventTypes.selectedLocationDay.id]: true }
          );
      }

      let rs = await updateDocument(
        {
          entity: "events",
          id: e.id,
        },
        {
          removedDays: removedDays,
        }
      );
      return rs;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const restoreDay = createAsyncThunk(
  "eventTypes/restoreDay",
  async (restoreDayAllLocations, { getState, rejectWithValue }) => {
    try {
      const { eventTypes } = getState();

      let e = { ...eventTypes.selectedLocationDay.event };

      let removedDays = { ...e.removedDays };
      if (restoreDayAllLocations) {
        if (removedDays[eventTypes.selectedLocationDay.selectedDay.day])
          delete removedDays[eventTypes.selectedLocationDay.selectedDay.day];
      } else {
        if (
          removedDays[eventTypes.selectedLocationDay.selectedDay.day][
            eventTypes.selectedLocationDay.id
          ]
        ) {
          removedDays[eventTypes.selectedLocationDay.selectedDay.day] = {
            ...removedDays[eventTypes.selectedLocationDay.selectedDay.day],
          };
          delete removedDays[eventTypes.selectedLocationDay.selectedDay.day][
            eventTypes.selectedLocationDay.id
          ];
        }
      }

      let rs = await updateDocument(
        {
          entity: "events",
          id: e.id,
        },
        {
          removedDays: removedDays,
        }
      );
      return rs;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const loadBrands = createAsyncThunk(
  "eventTypes/loadBrands",
  async (user) => {
    return getCollection("brands", {
      where: [
        [
          "users." + (user.parentUserID ? user.parentUserID : user.id),
          "==",
          true,
        ],
      ],
    });
  }
);

export const loadLocations = createAsyncThunk(
  "eventTypes/loadLocations",
  async (data, { getState, dispatch }) => {
    const { account } = getState();
    const { user } = account;
    if (data == "") return false;
    let brand = await getDocument("brands", data);
    if (_.isEmpty(brand)) return false;
    let locations = await getCollection("locations", {
      where: [
        ["brandID", "==", data],
        [
          "users." + (user.parentUserID ? user.parentUserID : user.id),
          "==",
          true,
        ],
      ],
    });
    locations = locations.filter(l=>!l.deleted
      &&
      ![
        "Non Participating",
        "Temporarily Closed",
        "Scheduled",
        "Referral"
      ].includes(l.status));
    return { brand, locations };
  }
);

export const loadRules = createAsyncThunk(
  "eventTypes/loadRules",
  async (brandID, { getState }) => {
    const { account } = getState();
    const { user } = account;
    if (!brandID) return false;
    let brand = await getDocument("brands", brandID);
    return { user, brand };
  }
);
export const save = createAsyncThunk(
  "eventTypes/save",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { eventTypes, account } = getState();
      const { user } = account;
      const {
        name,
        description,
        brand,
        eventPayout,
        eventPayoutTimeframe,
        eventPayoutType,
        eventCutoffDays,
        rules,
        locations,
        eventDuration,
        eventTimeframe,
        eventRepeatDay,
      } = eventTypes;

      let data = {
        userID: user.parentUserID ? user.parentUserID : user.id,
        active: true,
        paused: false,
        deleted: false,
        public: true,
        private: false,
        name: name,
        description: description,
        brandID: brand[".key"],
        brand: {
          id:brand[".key"],
          brand:brand.brand
        },
        locations: [],
        timezone: moment.tz.guess(),
        filterDays:{},
        filterWeeks:{},
        filterMonths:{},
        payout: eventPayout,
        payoutTimeframe: eventPayoutTimeframe,
        payoutType: eventPayoutType,
        cutoffDays: eventCutoffDays,
        rules: rules,
        modifiedAt: new Date(),
        createdAt: new Date(),
        allDay: eventTimeframe.allDay,
        starts: moment(eventDuration.starts).format("YYYY/MM/DD") + ' ' + moment(eventTimeframe.starts).format("hh:mm a"),
        ends: moment(eventDuration.starts).format("YYYY/MM/DD") + ' ' + moment(eventTimeframe.ends).format("hh:mm a"),
        duration: {
          starts:moment(eventDuration.starts).format("YYYY/MM/DD"),
          ends:moment(eventDuration.ends).format("YYYY/MM/DD")
        },
        repeat: "Never",
        repeatDays: eventRepeatDay.reduce((a,c)=>Object.assign({...a},{[c]:true}),{}),
        repeatDuration: 1,
        modifiedAt: new Date(),
      };

      let locationIDs = {};
      locations.forEach((location) => {
        locationIDs[location.value] = true;
        data.locations.push({ id: location.value, location: location.label });
      });
      data.locationIDs = locationIDs;
      let mEventStarts = convertDateTZ(moment(data.starts), data.timezone);
      let mEventEnds = convertDateTZ(moment(data.ends), data.timezone);
      let eventDates = [{ start: mEventStarts.unix(), end: mEventEnds.unix() }];
      data["firstDay"] = mEventStarts.unix();
      data["lastDay"] = mEventEnds.unix();
      if(!data.allDay) data = assignDateFilters(data, mEventStarts);
      let totalNumDays = moment(eventDuration.ends).diff(moment(eventDuration.starts), "days") + 1;
      for (let dayNum = 1; dayNum <= totalNumDays; dayNum = dayNum + 1) {
        let startDate = moment(data.starts);
        let newDate = moment(startDate.add(dayNum, "days"));
        let newDateStarts = convertDateTZ(
          moment(newDate.format("YYYY-MM-DD") + " " + moment(eventTimeframe.starts).format("hh:mm a")),
          data.timezone
        );
        let newDateEnds = convertDateTZ(
          moment(newDate.format("YYYY-MM-DD") + " " + moment(eventTimeframe.ends).format("hh:mm a")),
          data.timezone
        );
        let dayOfWeek = newDate.format("dddd");
        if (dayOfWeek in data.repeatDays) {
          data = assignDateFilters(data, newDateStarts);
          eventDates.push({
            start: newDateStarts.unix(),
            end: newDateEnds.unix(),
          });
          data["lastDay"] = newDateEnds.unix();
        }
      }
      Object.assign(data, { eventDates: eventDates });
      let rs = await createDocument({ entity: "events" }, data);
      return {...rs,brand:{...brand}};
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const eventTypesSlice = createSlice({
  name: "eventTypes",
  initialState: {
    processing: false,
    loading: true,
    loadingError: null,
    events: [],
    activeModal: null,
    selectedLocationDay: null,
    removingDay: false,
    removingDayError: null,
    fulfilled: false,
    shareLink: null,
    event: null,
    deleting: false,
    deleteError: null,
    showOnboarding: false,
    showUpgrade: false,
    onboardTitle: "What do you want to call your event?",
    step: 0,
    stepNameError: "",
    step1Error: "",
    step2Error: "",
    step3Error: "",
    step4Error: "",
    step8Error: "",
    name: "",
    description: "",
    locations: [],
    brand: {},
    brands: [],
    selectedBrand: null,
    optionsBrands: [],
    optionsLocations: [],
    locationBrand: {},
    locationsData: [],
    optionsDays: [
      { value: "Sunday", text: "Sunday" },
      { value: "Monday", text: "Monday" },
      { value: "Tuesday", text: "Tuesday" },
      { value: "Wednesday", text: "Wednesday" },
      { value: "Thursday", text: "Thursday" },
      { value: "Friday", text: "Friday" },
      { value: "Saturday", text: "Saturday" },
    ],
    eventRepeatDay: [],
    optionsAllDay: [
      { value: false, text: "No" },
      { value: true, text: "Yes" },
    ],
    eventTimeframe: {
      starts: "",
      ends: "",
      allDay: false,
    },
    eventDuration: {
      starts: moment().format("YYYY-MM-DD"),
      ends: moment().format("YYYY-MM-DD"),
    },
    payoutTypes: ["Receipts", "Total Sales"],
    eventPayoutType: "Receipts",
    eventPayout: 20,
    eventPayoutTimeframe: 30,
    eventCutoffDays: 7,
    rulesOptions: [],
    rules: [],
    saving: false,
    savingError: null
  },
  reducers: {
    setEvent: (state, action) => {
      state.event = action.payload;
    },
    setActiveModal: (state, action) => {
      state.removingDayError = null;
      state.restoringDayError = null;
      state.activeModal = action.payload;
    },
    setSelectedLocationDay: (state, action) => {
      state.selectedLocationDay = action.payload;
    },
    setShareLink: (state, action) => {
      state.shareLink = action.payload;
    },
    setShowOnboarding: (state, action) => {
      state.savingError = null;
      state.showOnboarding = action.payload;
    },
    setShowUpgrade: (state, action) => {
      state.showUpgrade = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
    setSelectedBrand: (state, action) => {
      state.selectedBrand = action.payload;
    },
    setLocationBrand: (state, action) => {
      state.locationBrand = action.payload;
    },
    setEventRepeatDay: (state, action) => {
      state.eventRepeatDay = action.payload;
    },
    setEventTimeframe: (state, action) => {
      state.eventTimeframe = action.payload;
    },
    setEventDuration: (state, action) => {
      state.eventDuration = action.payload;
    },
    setEventPayoutType: (state, action) => {
      state.eventPayoutType = action.payload;
    },
    setEventPayout: (state, action) => {
      state.eventPayout = action.payload;
    },
    setEventPayoutTimeframe: (state, action) => {
      state.eventPayoutTimeframe = action.payload;
    },
    setEventCutoffDays: (state, action) => {
      state.eventCutoffDays = action.payload;
    },
    setRules: (state, action) => {
      state.rules = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    changeTitle: (state, action) => {
      switch (state.step) {
        case 0:
          state.onboardTitle = "What do you want to call your event?";
          break;
        case 1:
          state.onboardTitle = "How would you describe your event?";
          break;
        case 2:
          state.onboardTitle = "Where will you host this event?";
          break;
        case 3:
          state.onboardTitle =
            "What days do you typically host fundraiser events?";
          break;
        case 4:
          state.onboardTitle =
            "What times do you like to start and end your events?";
          break;
        case 5:
          state.onboardTitle =
            "Within what date range can they schedule a fundraiser?";
          break;
        case 6:
          state.onboardTitle = "What are your donation details?";
          break;
        case 7:
          state.onboardTitle = "Do you have any rules and restrictions?";
          break;
        case 8:
          state.onboardTitle = "Confirm Event Details";
          break;
        case 9:
          state.onboardTitle = "Congratulations!";
          break;
      }
    },
    stepName: (state, action) => {
      if (state.name.length == 0) {
        state.stepNameError = "Please give your event a name.";
        return;
      }
      state.step += 1;
    },
    step1: (state, action) => {
      if (!state.brand[".key"]) {
        state.step1Error = "Brand is required.";
        return;
      }
      if (state.locations.length == 0) {
        state.step1Error = "Location is required.";
        return;
      }
      state.step = state.step + 1;
    },
    step2: (state, action) => {
      if (state.eventRepeatDay.length == 0) {
        state.step2Error = "Please select at least 1 day.";
        return;
      }
      state.step2Error = "";
      state.step += 1;
    },
    step3: (state, action) => {
      if (state.eventTimeframe.allDay) {
        state.eventTimeframe.starts = moment().format("YYYY/MM/DD") + " 00:00 am";
        state.eventTimeframe.ends = moment().format("YYYY/MM/DD") + " 11:59 pm";
      } else {
        if (
          state.eventTimeframe.starts.length == "" ||
          state.eventTimeframe.ends.length == ""
        ) {
          state.step3Error = "Please select both a start and end time.";
          return;
        }
        if (
          moment(state.eventTimeframe.ends, "h:mma").isBefore(
            moment(state.eventTimeframe.starts, "h:mma")
          )
        ) {
          state.step3Error = "End time must be later than the start time.";
          return;
        }
      }
      state.step3Error = "";
      state.step += 1;
    },
    step4: (state, action) => {
      if (
        state.eventDuration.starts.length == "" ||
        state.eventDuration.ends.length == ""
      ) {
        state.step4Error = "Please select both a start and end date.";
        return;
      }
      if (
        moment(state.eventDuration.ends).isBefore(
          moment(state.eventDuration.starts)
        )
      ) {
        state.step4Error = "End date must be later than the start date.";
        return;
      }
      state.step4Error = "";
      state.step += 1;
    },
    resetState: (state, action) => {
      state.onboardTitle = "What do you want to call your event?";
      state.step = 0;
      state.stepNameError = "";
      state.step2Error = "";
      state.step3Error = "";
      state.step4Error = "";
      state.step8Error = "";
      state.name = "";
      state.description = "";
      state.locations = [];
      state.brand = {};
      state.brands = [];
      state.selectedBrand = null;
      state.optionsBrands = [];
      state.optionsLocations = [];
      state.locationsData = [];
      state.locationBrand = {};
      state.eventRepeatDay = [];
      state.eventTimeframe = {
        starts: "",
        ends: "",
        allDay: false,
      };
      state.eventDuration = {
        starts: moment().format("YYYY-MM-DD"),
        ends: moment().format("YYYY-MM-DD"),
      };
      state.eventPayoutType = "Receipts";
      state.eventPayout = 20;
      state.eventPayoutTimeframe = 30;
      state.eventCutoffDays = 7;
      state.rulesOptions = [];
      state.rules = [];
    },
  },
  extraReducers: {
    [loadEventTypes.fulfilled]: (state, action) => {
      state.events = action.payload;
      state.loading = false;
      return;
    },
    [loadEventTypes.pending]: (state, action) => {
      state.loadingError = null;
      state.loading = true;
      return;
    },
    [loadEventTypes.rejected]: (state, action) => {
      state.loadingError = action.payload;
      state.loading = false;
      return;
    },
    [removeDay.fulfilled]: (state, action) => {
      state.activeModal = null;
      state.removingDay = false;
      state.events = state.events.map((e) =>
        e.id === action.payload.id ? action.payload : e
      );
      state.fulfilledEvent = action.payload;
      return;
    },
    [removeDay.pending]: (state, action) => {
      state.fulfilledEvent = null;
      state.removingDayError = null;
      state.removingDay = true;
      return;
    },
    [removeDay.rejected]: (state, action) => {
      state.removingDayError = action.payload;
      state.removingDay = false;
      return;
    },
    [restoreDay.fulfilled]: (state, action) => {
      state.activeModal = null;
      state.restoringDay = false;
      state.events = state.events.map((e) =>
        e.id === action.payload.id ? action.payload : e
      );
      state.fulfilledEvent = action.payload;
      return;
    },
    [restoreDay.pending]: (state, action) => {
      state.fulfilledEvent = null;
      state.restoringDayError = null;
      state.restoringDay = true;
      return;
    },
    [restoreDay.rejected]: (state, action) => {
      state.restoringDayError = action.payload;
      state.restoringDay = false;
      return;
    },
    [deleteEventType.fulfilled]: (state, action) => {
      state.events = state.events.filter((e) => e.id !== state.event.id);
      state.event = null;
      state.activeModal = null;
      state.deleting = false;
      return;
    },
    [deleteEventType.pending]: (state, action) => {
      state.deleteError = null;
      state.deleting = true;
      return;
    },
    [deleteEventType.rejected]: (state, action) => {
      state.deleteError = action.payload;
      state.deleting = false;
      return;
    },
    [loadBrands.fulfilled]: (state, action) => {
      if (action.payload?.length) {
        var brandData = {};
        var optBrands = [];
        var br = [];
        let brands = action.payload;
        brands.forEach((brand) => {
          optBrands.push({ value: brand.id, label: brand.brand });
          brandData = {
            [".key"]: brand.id,
            brand: brand.brand,
            logo: brand.logo ? brand.logo : false,
            menu: brand.menu ? brand.menu : "",
            rules: brand.rules ? brand.rules : [],
            website: brand.website ? brand.website : "",
          };
          br.push(brandData);
        });
        state.optionsBrands = optBrands;
        state.brands = br;
        if (optBrands.length == 1) {
          state.brand = brandData;
        }
      }
      return;
    },
    [loadBrands.pending]: (state, action) => {
      return;
    },
    [loadBrands.rejected]: (state, action) => {
      return;
    },
    [loadLocations.fulfilled]: (state, action) => {
      if (action.payload.locations?.length) {
        let locations = action.payload.locations;
        var optLocations = [];
        var locat = [];
        locations.forEach((location) => {
          if (!location.deleted) {
            locat[location.id] = location;
            optLocations.push({
              value: location.id,
              label:
                (action.payload.brand.includeExternal && location.externalID
                  ? location.externalID + " - "
                  : "") + location.location,
            });
          }
        });
        state.optionsLocations = optLocations.sort((a, b) =>
          a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        );
        state.locationsData = locat;
      }
      return;
    },
    [loadLocations.pending]: (state, action) => {
      return;
    },
    [loadLocations.rejected]: (state, action) => {
      return;
    },

    [loadRules.fulfilled]: (state, action) => {
      if (!_.isEmpty(action.payload.brand)) {
        let brand = action.payload.brand;
        let br = {};
        br = {
          [".key"]: brand.id,
          brand: brand.brand,
          logo: brand.logo ? brand.logo : false,
          menu: brand.menu ? brand.menu : "",
          rules: brand.rules ? brand.rules : [],
          website: brand.website ? brand.website : "",
        };
        state.brand = br;
        if (brand.rules) {
          state.rulesOptions = brand.rules;
        } else {
          state.rulesOptions = [];
        }
        if (action.payload.user.rules && action.payload.user.rules[brand.id])
          state.rulesOptions = state.rulesOptions.concat(
            action.payload.user.rules[brand.id]
          );
      }
      state.processing = false;
      return;
    },
    [loadRules.pending]: (state, action) => {
      state.processing = true;
      return;
    },
    [loadRules.rejected]: (state, action) => {
      state.processing = false;
      return;
    },
    [save.fulfilled]: (state, action) => {
      state.events.unshift({...action.payload});
      state.showOnboarding = false;
      state.step += 1;
      state.saving = false;
    },
    [save.pending]: (state, action) => {
      state.savingError = null;
      state.saving = true;
    },
    [save.rejected]: (state, action) => {
      state.savingError = action.payload;
      state.saving = false;
    },
  },
});

export const {
  setActiveModal,
  setSelectedLocationDay,
  setShareLink,
  setEvent,
  setShowOnboarding,
  setShowUpgrade,
  setName,
  setDescription,
  setLocations,
  setSelectedBrand,
  setLocationBrand,
  setEventRepeatDay,
  setEventTimeframe,
  setEventDuration,
  setEventPayoutType,
  setEventPayout,
  setEventPayoutTimeframe,
  setEventCutoffDays,
  setRules,
  setStep,
  changeTitle,
  stepName,
  step1,
  step2,
  step3,
  step4,
  resetState,
} = eventTypesSlice.actions;
export default eventTypesSlice.reducer;
